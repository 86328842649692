<template>
  <div>
    <b-row>
      <b-col cols="12" class="mb-2">
        <h2 class="mb-0 text-uppercase font-weight-bolder">étape 2 : recueil des besoins</h2>
        <h6>Renseignez les besoins exprimés</h6>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <sante-tns-component v-if="getRisqueStatut('SANTETNS')" ref="saisieTNS" />
        <sante-seniors-component v-else-if="getRisqueStatut('SANTESENIORS')" ref="saisieSanteSenior" />
        <deux-roues-component v-else-if="getRisqueStatut('2-ROUES')" ref="saisie2Roues" />
        <emprunteur-component v-else-if= "getRisqueStatut('EMPRUNTEUR')" ref="saisieEmprunteur" />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import SanteTnsComponent from './BesoinsSanteTns.vue'
import SanteSeniorsComponent from './BesoinsSanteSenior.vue'
import DeuxRouesComponent from './Besoins2Roues.vue'
import EmprunteurComponent from './BesoinEmprunteur.vue'

export default {
  components: {
    // UI
    SanteTnsComponent,
    SanteSeniorsComponent,
    DeuxRouesComponent
    ,EmprunteurComponent,
    // BTV
    BRow,
    BCol,
  },
  computed: {
    ...mapGetters(['getQWCheckedRisque'])
  },
  methods: {
    getRisqueStatut(value) {
      return (value && this.getQWCheckedRisque) ? this.formatText(this.getQWCheckedRisque) == value : ''
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
