export function santeTnsRisque(produits,garantie,exigences) {
    let produitsResult=[]
    produits.map((pr)=>{
        switch(pr.produit.produit_ws_nom){
            case 'TNS_SPHERIA':
                produitsResult.push(tnsSpheriaProduit(pr,garantie,exigences));
                break;
            case 'ACTISAN':
                produitsResult.push(tnsECAProduit(pr,garantie,exigences));
                break;
            default :
                produitsResult.push(defaultProduit(pr))
        }
    })
    return produitsResult;
}

function tnsSpheriaProduit(produit,garantie,exigences){


    let FORMULE_1={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences)
        },
        hospitalisation:{
            value:1,
            choose:searchoption('Hospitalisation',exigences)
        },
        soinsCourants:{
            value:1,
            choose:searchoption('Soins Courants',exigences)
        },
        aidesAuditives:{
            value:1,
            choose:searchoption('Aides auditives',exigences)
        },

        grNiveau:'Economique'
    }
    let FORMULE_2={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences)
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences)
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences)
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences)
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences)
        },

        grNiveau:'Complète'
    }
    let FORMULE_3={
        optique:{
            value:3,
            choose:searchoption('Optique',exigences)
        },
        dentaire:{
            value:3,
            choose:searchoption('Dentaire',exigences)
        },
        hospitalisation:{
            value:3,
            choose:searchoption('Hospitalisation',exigences)
        },
        soinsCourants:{
            value:3,
            choose:searchoption('Soins Courants',exigences)
        },
        aidesAuditives:{
            value:3,
            choose:searchoption('Aides auditives',exigences)
        },

        grNiveau:'Complète'
    }
    let FORMULE_4={
        optique:{
            value:4,
            choose:searchoption('Optique',exigences)
        },
        dentaire:{
            value:4,
            choose:searchoption('Dentaire',exigences)
        },
        hospitalisation:{
            value:4,
            choose:searchoption('Hospitalisation',exigences)
        },
        soinsCourants:{
            value:4,
            choose:searchoption('Soins Courants',exigences)
        },
        aidesAuditives:{
            value:4,
            choose:searchoption('Aides auditives',exigences)
        },

        grNiveau:'Haut de gamme'
    }
    let FORMULE_5={
        optique:{
            value:5,
            choose:searchoption('Optique',exigences)
        },
        dentaire:{
            value:5,
            choose:searchoption('Dentaire',exigences)
        },
        hospitalisation:{
            value:5,
            choose:searchoption('Hospitalisation',exigences)
        },
        soinsCourants:{
            value:5,
            choose:searchoption('Soins Courants',exigences)
        },
        aidesAuditives:{
            value:5,
            choose:searchoption('Aides auditives',exigences)
        },
        grNiveau:'Haut de gamme'
    }

    switch(produit.tarif.formule){
        case 'Formule 1':
            calcultauxtarif(FORMULE_1,garantie,produit)
        break;
        case 'Formule 2':
            calcultauxtarif(FORMULE_2,garantie,produit)
        break;
        case 'Formule 3':
            calcultauxtarif(FORMULE_3,garantie,produit)
        break;
        case 'Formule 4':
            calcultauxtarif(FORMULE_4,garantie,produit)
        break;
        case 'Formule 5':
            calcultauxtarif(FORMULE_5,garantie,produit)
        break;
        default :
            produit.tarif.tauxGarantie=0
            produit.tarif.taux=0
    }
    return produit
}
function tnsECAProduit(produit,garantie,exigences){
    let ACCESS={
        optique:{
            value:1,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:1,
            choose:searchoption('Dentaire',exigences)
        },
        hospitalisation:{
            value:1,
            choose:searchoption('Hospitalisation',exigences)
        },
        soinsCourants:{
            value:1,
            choose:searchoption('Soins Courants',exigences)
        },
        aidesAuditives:{
            value:1,
            choose:searchoption('Aides auditives',exigences)
        },

        grNiveau:'Economique'
    }
    let MEDIUM={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences)
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences)
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences)
        },
        aidesAuditives:{
            value:1,
            choose:searchoption('Aides auditives',exigences)
        },

        grNiveau:'Complète'
    }
    let MEDIANE={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences)
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences)
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences)
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences)
        },

        grNiveau:'Complète'
    }
    let MEDIANE_PLUS={
        optique:{
            value:2,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences)
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences)
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences)
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences)
        },

        grNiveau:'Complète'
    }
    let INTEGRALE={
        optique:{
            value:3,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences)
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences)
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences)
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences)
        },

        grNiveau:'Haut de gamme'
    }
    let INTEGRALE_PLUS={
        optique:{
            value:3,
            choose:searchoption('Optique',exigences),
        },
        dentaire:{
            value:2,
            choose:searchoption('Dentaire',exigences)
        },
        hospitalisation:{
            value:2,
            choose:searchoption('Hospitalisation',exigences)
        },
        soinsCourants:{
            value:2,
            choose:searchoption('Soins Courants',exigences)
        },
        aidesAuditives:{
            value:2,
            choose:searchoption('Aides auditives',exigences)
        },

        grNiveau:'Haut de gamme'
    }


    switch(produit.tarif.formule){
        case 'ACCESS':
            calcultauxtarif(ACCESS,garantie,produit)
        break;
        case 'MEDIUM':
            calcultauxtarif(MEDIUM,garantie,produit)
        break;
        case 'MEDIANE':
            calcultauxtarif(MEDIANE,garantie,produit)
        break;
        case 'MEDIANE_PLUS':
            calcultauxtarif(MEDIANE_PLUS,garantie,produit)
        break;
        case 'INTEGRALE':
            calcultauxtarif(INTEGRALE,garantie,produit)
        break;
        case 'INTEGRALE_PLUS':
            calcultauxtarif(INTEGRALE_PLUS,garantie,produit)
        break;
        default :
            produit.tarif.tauxGarantie=0
            produit.tarif.taux=0
    }
    return produit
}

function calcultauxtarif(pgniveau,garantie,produit){
    let total=0
    let cpt=0
    Object.entries(pgniveau).forEach(([key, o]) => {
        if(key!=='grNiveau'){
            let somme=0;
            if(o.value==o.choose){
                somme=1
            }
            total+=somme;
            cpt++
        }
    });
    produit.tarif.tauxGn  = pgniveau.grNiveau==garantie ? 1 : 0
    produit.tarif.tauxCouv= total/cpt
}

function searchoption(option,exigences){
    let tmpOption= exigences.find( pg => pg.label === option);
    return tmpOption ? mapAndGetResult(tmpOption) : 1
}

function mapAndGetResult(exigence){
    let tmpEx= exigence.exigenceniveaux.find( egn => egn.id === exigence.exigenceNiveauID[0]);
    if(tmpEx && tmpEx.exigence_niveau_libel){
        switch(tmpEx.exigence_niveau_libel){
            case 'NON':
                return 0;
            case 'MIN':
                return 1;
            case 'MOYEN':
                return 2
            case 'FORT':
                return 3;
            case 'MAX':
                return 4;
            case 'MAXPLUS':
                return 5;
            default:
                return 1;
        }
    }else{
        return 1;
    }
}

function defaultProduit(produit){
    produit.tarif.taux=0
    return produit
}