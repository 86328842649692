<template>
  <div>
    <b-overlay :show="showLoading" no-wrap />
    <b-row v-if="tarifsDataLocal.length > 0">
      <b-col class="mb-2" cols="12">
        <h2 class="mb-0 text-uppercase font-weight-bolder">étape 3 : Comparaison des offres</h2>
        <b-button v-if="tarifsDataLocal.length > 0 && hasProduitAutoriser" variant="primary" class="float-right"  :disabled="disablePoursuivre"  size="lg" @click="creeDevisAndClient()">Poursuivre vers la création d'un devis</b-button>
        
      </b-col>
      <b-col lg="12">
                <b-button variant="primary" class="float-left my-2" size="lg" @click="backStep">
                    Précédent
                </b-button>
                <b-button v-if="tarifsDataLocal.length > 0 && !hasProduitAutoriser" variant="primary" class="float-right my-2" size="lg" @click="redirecToDemandeCode()" disable>Demande d'ouverture de code</b-button>
            </b-col>
      <b-col>
      <b-col lg="12">
        <b-alert variant="warning" show v-show="getAssureursRisque()" class="mt-1">
          <div class="alert-body">
            <p v-html="getAssureursRisque()"></p>
          </div>
        </b-alert>
      </b-col>
        <b-row>
          <b-col cols="12">
            <b-dropdown class="mb-2 float-right" right split text="Trier par" variant="outline-primary">
              <b-dropdown-item @click="sorByPrice('DOWN')">Prix décroissant</b-dropdown-item>
              <b-dropdown-item @click="sorByPrice('UP')">Prix croissant</b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col v-if="showCompareTable" cols="12">
              <b-table-simple v-for="(tarif, index) in tarifsDataLocal" :key="index+ '-tarif'" bordered caption-top class="mb-4" hover responsive>
                <b-thead class="offere_thClass">
                  <b-tr>
                    <b-th class="text-center" style="width: 20%">produit</b-th>
                    <b-th class="text-center" style="width: 25%">documents</b-th>
                    <b-th class="text-center" style="width: 20%">options</b-th>
                    <b-th class="text-center" style="width: 20%">couverture</b-th>
                    <b-th class="text-center" style="width: 15%">prix</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody class="offere_tdClass">
  
                  <!-- produit -->
                  <b-td class="text-center">
                    <p class="box-custom-text">{{ tarif.produit.produit_nom }}</p>
                    <template v-if="tarif.produit.nom_assureur =='ECA_ASSURANCES'">
                      <b-img :src="require('@/assets/images/logo/assureur/eca-assurances.png')" alt="logo" class="mb-1" height="auto" width="100px" />
                    </template>
                    <template v-else-if="tarif.produit.nom_assureur =='SPVIE'">
                      <b-img :src="require('@/assets/images/logo/assureur/spvie.png')" alt="logo" class="mb-1" height="auto" width="100px" />
                    </template>
                    <template v-else-if="tarif.produit.nom_assureur =='COVERITY'">
                      <b-img :src="require('@/assets/images/logo/assureur/coverity.png')" alt="logo" class="mb-1" height="auto" width="100px" />
                    </template>
                    <template v-else>
                      <b-img :src="require('@/assets/images/logo/assureur/SwissLife.png')" alt="logo" class="mb-1" height="auto" width="100px" />
                    </template>
                    <p class="box-custom-text">{{ tarif.produit.nom_com_assureur }}</p>
                  </b-td>
  
                  <!-- DOCUMENTS -->
                  <b-td>
                    <div v-for="(documentAssureur, indexDocument) in tarif.produit.documentAssureur" :key="indexDocument + '-document-assureur'">
                      <span v-if="documentAssureur.document && documentAssureur.document.document_type.id !== 108" class="text-nowrap cursor-pointer" @click="showFile(documentAssureur.document_id, documentAssureur.document.document_type.type)">
                        <feather-icon color="#bf2626" icon="FileIcon" size="14" style="margin-bottom: 4px" />
                        <span class="text-nowrap">{{ documentAssureur.document.document_type.type }}</span>
                      </span>
                    </div>
                  </b-td>
  
                  <!-- OPTIONS -->
                  <b-td>
                    <template>
                      <!-- GARANTIES -->
                      <b-row v-for="(option, indexK) in tarif.tarif.optionCompatiblesWithGaranties" :key="indexK + '-garantie'">
                        <b-col v-if="option.isShow" md="12">
                          <p>{{ option.libelle_comm }} </p>
                          <b-form-select :value="option.isSelectedProduitGarantieNiveauID" class="mb-2" size="sm" @change="onChangeNiveau($event, index, indexK)">
                            <b-form-select-option v-for="(optPGN, indexL) in option.produitgarantiesniveaux" :key="indexL + '-produitGarantieNiveau'" :value="optPGN.id" > {{ optPGN.libelle }} </b-form-select-option>
                          </b-form-select>
                        </b-col>
                      </b-row>
    
                      <!-- TAUX DE COMMISSIONS -->
                      <b-row v-show="false">
                        <b-col md="12">
                          <p >Taux de commission :</p>
                        </b-col>
                        <b-col md="12">
                          <b-form-select v-model="tarif.tarif.tauxDeCommission" size="sm" @change="onChangeTaux($event, index, tarif.tarif.formule)">
                            <b-form-select-option v-for="(taux, indexTaux) in tarif.produit.produitCommissions" :key="indexTaux + '-taux_de_commission'" :value="taux.commission_value"> {{ taux.commission_text }} </b-form-select-option>
                          </b-form-select>
                        </b-col>
                      </b-row>
                    </template>
                  </b-td>
  
                  <!-- ADÉQUATION AU BESOIN -->
                  <b-td>
                    <b-row v-for="(pgne, indexJ) in tarif.tarif.niveauGarantie.produit_garantie_niveau_exigence" :key="indexJ + '-exigence'">
                      <b-col class="m-0 p-0" lg="6">
                        <span v-b-tooltip.hover.top="explicationExigenceLibel(pgne.exigence_niveau.exigence.exigence_produits_details, tarif.produit.produit_id)" v-b-tooltip.hover.v-primary class="cursor-pointer"> {{ pgne.exigence_niveau.exigence.label }} </span>
                      </b-col>
                      <b-col class="m-0 p-0 ml-auto" lg="5"> {{ pgne.exigence_niveau.exigence.id == 278 || pgne.exigence_niveau.exigence.id == 277 ? pgne.label_correspondant + '*' : pgne.label_correspondant + '' }} </b-col>
                    </b-row>
                  </b-td>
  
                  <!-- PRIX -->
                  <b-td>
                    <div class="text-center">
                      <div class="px-0">
                        <h4>{{ tarif.tarif.niveauGarantie.libelle_commercial }}</h4>
                        <b-badge :variant="sourceVariant(tarif.tarif.niveauGarantie.garantie_niveau.garantie_niveau_libel.toUpperCase())" class="mb-1">
                          {{ tarif.tarif.niveauGarantie.garantie_niveau.garantie_niveau_libel }}
                        </b-badge>
                        <div class="annual-plan">
                          <div class="plan-price mt-2">
                            <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                            <span class="pricing-basic-value font-weight-bolder text-primary">{{ Number(tarif.tarif.tarifs.mensuelle).toFixed(2) }}</span>
                            <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/mois</sub>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-td>
                </b-tbody>
              </b-table-simple>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col lg="12">
        <b-alert variant="warning" show class="mt-1">
          <div class="alert-body">
            <p v-html="getAssureursRisque()"></p>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-2" cols="12">
        <p>* Adh&eacute;rents aux dispositifs de pratique tarifaire maitris&eacute;e</p>
        <app-collapse id="collapse-besoin" type="margin">
            <app-collapse-item ref="tab-collapse-item-0"  :is-visible="true" title="<h4 class='title-custom-wizard'><u>SwissLife - Détail des options<u></h4>">
              <ul>
                <li>
                  <strong>Le module Retraité +</strong> permet de bénéficier de forfaits supplémentaires en médecines douces, pharmacie et dentaire (non remboursés par la Sécurité sociale ou tout autre régime obligatoire) ou aide aux frais d’obsèques. Il propose également un forfait complémentaire pour la chambre particulière en cas d’hospitalisation et le versement d’un capital en cas de perte totale et irréversible d’autonomie.
                </li>
              
                <li>
                  <strong>L’option Essentiel Pharma</strong> permet d’optimiser le montant de votre cotisation en limitant la prise en charge aux médicaments remboursés à 65 % par la Sécurité sociale.
                </li>
              </ul>
            </app-collapse-item>

            <app-collapse-item ref="tab-collapse-item-0"  :is-visible="true" title="<h4 class='title-custom-wizard'><u>ECA - Détail des options<u></h4>">
                <ul>
                  <li>
                    <strong>L’option Renfort + </strong> permet de bénéficier de garanties en médecines douces et médecines non remboursées par l’Assurance Maladie obligatoire. Elle comprend des forfaits supplémentaires pour la prise en charge de la chambre particulière en secteur conventionné, pour les prothèses dentaires, l’implantologie et la parodontologie non remboursées par l’Assurance Maladie Obligatoire. Elle propose également un capital forfaitaire en cas de décès accidentel de l’Adhérent ou de l’un des assurés au contrat ainsi qu’un capital perte d’autonomie. 
                  </li>
              
                  <li>
                    <strong>L’option Pharma +</strong> vise à prendre en charge les médicaments remboursés à 65%, 30% et 15%  par l’Assurance Maladie Obligatoire.                
                  </li>
                </ul>
              </app-collapse-item>
          </app-collapse>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <b-button variant="primary" class="float-left" size="lg" @click="backStep"> Précédent </b-button>
        <b-button v-if="tarifsDataLocal.length > 0 && hasProduitAutoriser" variant="primary" class="float-right"  :disabled="disablePoursuivre"  size="lg" @click="creeDevisAndClient()">Poursuivre vers la création d'un devis</b-button>
        <b-button v-if="tarifsDataLocal.length > 0 && !hasProduitAutoriser" variant="primary" class="float-right" size="lg" @click="redirecToDemandeCode()" disable>Demande d'ouverture de code</b-button>
      </b-col>
    </b-row>
    <b-modal id="modal-display-document" ref="modal-display-document" :size="document.extensionDocument === 'PDF' ? 'xl' : 'sm'" :title="document.name" ok-only ok-title="Fermer" ok-variant="outline-secondary" @ok="clearDocumentDisplay">
      <form ref="form" :style="{ height: document.extensionDocument === 'PDF' ? '80vh' : 'auto', overflow: 'hidden' }">
        <b-img v-if="document.extensionDocument === 'IMAGE'" :alt="document.name" :src="document.base64" fluid />
        <iframe v-else-if="document.extensionDocument === 'PDF'" :src="document.base64" height="100%" width="100%" :style="hideToolbar()" />
      </form>
    </b-modal>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import {
  BOverlay, VBTooltip, BRow, BCol, BDropdown, BDropdownItem, BTableSimple, BThead, BTbody, BTr, BTh, BTd, BImg, BBadge, BButton, BFormSelect, BFormSelectOption, BModal, BAlert
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'

export default {
  components: {
    // Other
    BOverlay,
    AppCollapse,
    AppCollapseItem,
    BImg,
    BBadge,
    BButton,
    BModal,
    // grid
    BRow,
    BCol,
    // dropdown
    BDropdown,
    BDropdownItem,
    // table
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    // select
    BFormSelect,
    BFormSelectOption,
    BAlert
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      checkedRisque: null,
      statistiqueUpdated: false,
      statistiqueObjet: {
        id: null,
        courtier_fk: null,
        user_fk: null,
        risque_fk: null,
        marketplace: 0,
        demandeCode: 0,
        risque_nom: null,
        tarifs: [],
        qw_tarif: {}
      },
      hasProduitAutoriser: false,
      tarifsDataLocal: [],
      produitLocal: {
        produit_id: '',
        risque_id: '',
        produit_nom: '',
        nom_assureur: '',
      },
      showCompareTable: true,
      showLoading: false,
      document: {
        base64: null,
        name: null,
        nameToDownload: null,
        extensionDocument: null
      },
      currentUser: JSON.parse(localStorage.getItem('userData')),
      disablePoursuivre:false

    }
  },
  computed: {
    ...mapGetters(['getTarifsData']),
    ...mapGetters(['getProduitTarificateur']),
    ...mapGetters(['getComposeDataTarif']),
    ...mapGetters(['getQWHasProduitAutoriser']),
    ...mapGetters(["getQWCheckedRisque"]),
  },
  watch: {
    getProduitTarificateur: {
      handler(val) {
        this.produitLocal = val
        this.initProduitAutoriser(val)
      },
      deep: true,
    },
    getTarifsData: {
      handler(val, old) {
        if (val.length > 0) {
          this.getAssureursRisque()
          if (old.length === 0) {
            this.arrayProduit(val)
            this.clearStatistiqueQWTarif()
            this.statistiqueQWTarif()
          } 
        }
      },
      deep: true,
    },
    hasProduitAutoriser: {
      immediate: true,
      handler(val) {
        this.$store.commit('setQWHasProduitAutoriser', { hasProduitAutoriser: val })
      }
    },
    getQWCheckedRisque: {
      immediate: true,
      handler(val) {
        this.checkedRisque = val
      }
    }
  },
  methods: {
    explicationExigenceLibel(exProduitsDetails, produitSelected) {
      let expDetail = exProduitsDetails.find((exp) => exp.produit_id == produitSelected)
      return expDetail != undefined ? expDetail.pivot.info : ''
    },
    getAssureursRisque() {
      var innerHTML = null
      var assureurs = this.getTarifsData.reduce((assureurs, pr) => {
        if(Object.keys(pr.produit).length != 0 && pr.tarif <= 0){ 
            assureurs.push(pr.produit.nom_com_assureur + ' (' + pr.produit.produit_nom + ')');
        }
        return assureurs;
      }, []);
      
      let swlProduit = this.getTarifsData.find((pr)=>pr.produit.nom_com_assureur=='SL_Sante_Retraites');
      if((swlProduit === undefined && eval(this.getComposeDataTarif.enfantCharge) > 0) || this.getComposeDataTarif?.devis?.loi_madelin){
      assureurs.push('SWISSLIFE PREVOYANCE ET SANTE (SwissLife Santé Retraités)')
      }
      if(assureurs.length > 0){
      innerHTML = `Aucun tarif disponible pour ${assureurs.length == 1 ? 'la compagnie' : 'les companies'} : <strong>${assureurs.join(", ")}</strong>`
      }
      return innerHTML
    },
    // ****************************************
    // Additional Functions
    // ****************************************
    clearStatistiqueQWTarif() {
        this.statistiqueObjet = {
            id: null,
            courtier_fk: null,
            user_fk: null,
            risque_fk: null,
            marketplace: 0,
            demandeCode: 0,
            risque_nom: null,
            tarifs: [],
            qw_tarif: {}
        }
    },
    statistiqueQWTarif() {
      this.statistiqueObjet.courtier_fk = this.currentUser.courtier_user[0].courtier_id
      this.statistiqueObjet.user_fk     = this.currentUser.courtier_user[0].user_id
      this.statistiqueObjet.risque_fk   = this.produitLocal[0].risque_id
      this.statistiqueObjet.risque_nom  = this.checkedRisque
      this.statistiqueObjet.tarifs      = this.tarifsDataLocal
      this.statistiqueObjet.qw_tarif    = this.getComposeDataTarif

      this.$http.post('/tarification-rapide/addOrUpdateTarififcationRapide', this.statistiqueObjet).then((res) => {
        if(res.data.success) {
          this.statistiqueUpdated = false
          this.statistiqueObjet.id = res.data.data.id;
        }
      })
    },
    updateStatistiqueQWTarif(marketplace, demandeCode) {
      this.showLoading = true
      if (!this.statistiqueUpdated) {
        if (this.tarifsDataLocal.length > 0) {
          if (this.hasProduitAutoriser && marketplace)
            this.statistiqueObjet.marketplace = 1
          else if (demandeCode) {
            this.statistiqueObjet.demandeCode = 1
          }

          this.$http.post('/tarification-rapide/addOrUpdateTarififcationRapide', this.statistiqueObjet).then(res => {
            if (res.data.success) {
              this.statistiqueUpdated = true
            }
          }).catch((e) => {
          this.showLoading = false
          this.disablePoursuivre = false
        });
        }
      }
      this.showLoading = false
    },
    backStep() {
      this.$store.commit('setQWStepTarificateur', { step: { indexStep: 1, active: 'besoins' } })
      this.$store.commit('setTarifsData', [])
      this.$store.commit('setComposeDataTarif', null)
    },
    sorByPrice(typeSort) {
      switch (typeSort) {
        case 'UP':
          this.tarifsDataLocal.sort((a, b) => a.tarif.tarifs.mensuelle - b.tarif.tarifs.mensuelle)
          break

        case 'DOWN':
          this.tarifsDataLocal.sort((a, b) => b.tarif.tarifs.mensuelle - a.tarif.tarifs.mensuelle)
          break

        default:
          this.tarifsDataLocal.sort((a, b) => b.tarif.tarifs.mensuelle - a.tarif.tarifs.mensuelle)
          break
      }
    },
    onChangeNiveau(event, indexTarif, indexOCWithGaranties) {
      const { tarifsBase } = this.tarifsDataLocal[indexTarif].tarif
      this.tarifsDataLocal[indexTarif].tarif.tarifs = { ...tarifsBase }
      this.tarifsDataLocal[indexTarif].tarif.optionCompatiblesWithGaranties[indexOCWithGaranties].isSelectedProduitGarantieNiveauID = event
      let CountSelectedByOui = 0
      this.tarifsDataLocal[indexTarif].tarif.optionCompatiblesWithGaranties.forEach(element => {
        if (element.isShow) {
          const produitGarantieNiveauLibelle = element.produitgarantiesniveaux.find(item => item.id === element.isSelectedProduitGarantieNiveauID).libelle

          if (produitGarantieNiveauLibelle.toLowerCase() !== 'non' && CountSelectedByOui >= 1) {
            this.tarifsDataLocal[indexTarif].tarif.tarifs = { ...tarifsBase }
            const idsToCalculateBoth = [37, 63]
            const RenfortAndEssentielPharmaTarifs = this.tarifsDataLocal[indexTarif].tarif.optionCompatiblesWithGaranties.find(item => idsToCalculateBoth.includes(item.id)).tarif

            this.tarifsDataLocal[indexTarif].tarif.tarifs.annuelle += Number(RenfortAndEssentielPharmaTarifs.annuelle)
            this.tarifsDataLocal[indexTarif].tarif.tarifs.mensuelle += Number(RenfortAndEssentielPharmaTarifs.mensuelle)
            this.tarifsDataLocal[indexTarif].tarif.tarifs.semestrielle += Number(RenfortAndEssentielPharmaTarifs.semestrielle)
            this.tarifsDataLocal[indexTarif].tarif.tarifs.trimestrielle += Number(RenfortAndEssentielPharmaTarifs.trimestrielle)
            CountSelectedByOui += 1
          } else if (produitGarantieNiveauLibelle.toLowerCase() !== 'non' && CountSelectedByOui < 1) {
            this.tarifsDataLocal[indexTarif].tarif.tarifs.annuelle += Number(element.tarif.annuelle)
            this.tarifsDataLocal[indexTarif].tarif.tarifs.mensuelle += Number(element.tarif.mensuelle)
            this.tarifsDataLocal[indexTarif].tarif.tarifs.semestrielle += Number(element.tarif.semestrielle)
            this.tarifsDataLocal[indexTarif].tarif.tarifs.trimestrielle += Number(element.tarif.trimestrielle)

            CountSelectedByOui += 1
          }
        }
      })

      // Set tarification data in store
      this.$store.commit('setTarifsData', this.tarifsDataLocal)
    },
    onChangeTaux(event, indexTarif, formule) {
      this.showLoading = true
      let data = {
        regime: this.getComposeDataTarif.assurePrincipal.regime,
        dateNaissance: this.getComposeDataTarif.assurePrincipal.dateNaissance,
        dateEffet: this.getComposeDataTarif.dateEffet,
        produit: 'SL_Sante_Retraites',
        formule,
        taux_commission: event
      }

      if (this.getComposeDataTarif.assurePrincipal.regime !== 'REGIME_ALSACE_MOSELLE' || this.getComposeDataTarif.conjoint.regime !== 'REGIME_ALSACE_MOSELLE') {
        data.codePostal = this.getComposeDataTarif.assurePrincipal.codePostal
      }

      if (this.getComposeDataTarif.isConjoint == 1) {
        data.assurerVotreConjoint = 'OUI'
        data.dateNaissanceConjoint = this.getComposeDataTarif.conjoint.dateNaissance
        data.regimeConjoint = this.getComposeDataTarif.conjoint.regime
      } else {
        data.assurerVotreConjoint = 'NON'
      }
      this.$http
        .post('portefeuilles/calculTarifWithTauxByNiveau', data)
        .then(async response => {
          if (response.data) {
            // Set tarifs
            this.tarifsDataLocal[indexTarif].tarif.tarifs = response.data.tarifs
            this.tarifsDataLocal[indexTarif].tarif.tarifsBase = response.data.tarifs
            this.tarifsDataLocal[indexTarif].tarif.optionsCompatibles = response.data.optionsCompatibles
            await this.tarifsDataLocal[indexTarif].tarif.optionCompatiblesWithGaranties.forEach(element => {
              element.tarif = this.tarifsDataLocal[indexTarif].tarif.optionsCompatibles[element.libelle_comm].tarif
            })

            await this.tarifsDataLocal[indexTarif].tarif.optionCompatiblesWithGaranties.forEach((element, indexOCWithGaranties) => {
              this.onChangeNiveau(element.isSelectedProduitGarantieNiveauID, indexTarif, indexOCWithGaranties)
            })
            // Set in store
            await this.$store.commit('setTarifsData', this.tarifsDataLocal)
          }
        })
        .catch(err => {
          console.log(err)
          this.showLoading = false
        })
        .finally(() => {
          this.showLoading = false
        })
    },
    async onChangeTauxGlobale(event, index){

      this.showLoading=true
      let data = {
        isConjoint : this.getComposeDataTarif.isConjoint,
        produitAssureur : this.tarifsDataLocal[index].produit.nom_assureur,
        dateEffet: this.getComposeDataTarif.dateEffet,
        produitTarif : this.tarifsDataLocal[index].tarif,
        risque : this.tarifsDataLocal[index].produit.risque,
        produit : this.tarifsDataLocal[index].produit,
        regime: this.getComposeDataTarif.assurePrincipal.regime,
        dateNaissance: this.getComposeDataTarif.assurePrincipal.dateNaissance, 
        codePostal : this.getComposeDataTarif.assurePrincipal.codePostal,
        conjoint : this.getComposeDataTarif.conjoint,
        enfantsArray : this.getComposeDataTarif.enfantsArray,
        tauxCommision : event
      }

      await this.$http.post(`portefeuilles/getTarifByFormule`,data).then((response)=>{
        if(response.data.statut==201){
          this.tarifsDataLocal[index].tarif.tarifs = response.data.data.tarifs
          this.tarifsDataLocal[index].tarif.tarif_base = response.data.data.tarifs
          this.showLoading=false
        }
        }).catch((err)=>{
        this.showLoading=false
      })

    },
    redirecToDemandeCode() {
      this.updateStatistiqueQWTarif(0, 1)
      const isAdministrateurCabinet = this.currentUser.courtier_user[0].isPrincipal
      const civiliteAdministrateurCabinet = this.capitalizeFirstLetter(this.currentUser.courtier_user[0].courtier.courtier_user[0].user.personne_physique.civilite.toLowerCase())
      const prenomAdministrateurCabinet = this.currentUser.courtier_user[0].courtier.courtier_user[0].user.personne_physique.prenom
      const nomAdministrateurCabinet = this.currentUser.courtier_user[0].courtier.courtier_user[0].user.personne_physique.nom

      if (isAdministrateurCabinet) {
        this.$router.push({ name: 'fiche-demande-ouverture-code', query: {tarification_rapide: this.statistiqueObjet.id}})
      } else {
        this.$swal({
          html: `<p class="text-justify" style="font-size: 15px">Pour faire une demande de code, merci de vous rapprocher de votre administrateur de cabinet : ${civiliteAdministrateurCabinet} ${prenomAdministrateurCabinet} ${nomAdministrateurCabinet}.</p>`,
          icon: 'info',
          showCancelButton: false,
          showConfirmButton: false
        })
      }
    },
    initProduitAutoriser(produits) {
      const produitsAutorised = this.currentUser.courtier_user[0].courtier.produits_autorise
      if (produitsAutorised.length > 0) {
        const result = produitsAutorised.find(prAutorised => produits.map(p => p.produit_id).find((pID) => pID === prAutorised.produit_id))
        if (result !== undefined) {
          this.hasProduitAutoriser = true
        } else {
          this.hasProduitAutoriser = false
        }
      } else {
        this.hasProduitAutoriser = false
      }
    },

    // ****************************************
    // Documents Functions
    // ****************************************
    showFile(id, type) {
      this.displayDocument(id, type, type)
    },
    displayDocument(id, name, NomDuDocument) {
      this.$http
        .get(`/document/generate_base64_for_document/${id}`)
        .then(res => {
          if (res.data.success) {
            this.clearDocumentDisplay()
            if (!res.data.data.isDownload && res.data.data.extensionDocument === 'IMAGE') {
              this.document.base64 = res.data.data.base64
              this.document.name = NomDuDocument || name
              this.document.nameToDownload = name
              this.document.extensionDocument = res.data.data.extensionDocument
              this.$refs['modal-display-document'].show()
            } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'PDF') {
              this.document.base64 = res.data.data.base64
              this.document.name = NomDuDocument || name
              this.document.nameToDownload = name
              this.document.extensionDocument = res.data.data.extensionDocument
              this.$refs['modal-display-document'].show()
            }
          } else {
            this.clearDocumentDisplay()
            this.messageToast(res.data.message, 'Erreur', 'error')
          }
        })
        .catch(err => {
          this.clearDocumentDisplay()
          this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          console.error(err)
        })
    },
    clearDocumentDisplay() {
      this.document.base64 = null
      this.document.name = null
      this.document.nameToDownload = null
      this.document.extensionDocument = null
    },

    // ****************************************
    // Devis Functions
    // ****************************************
    creeDevisAndClient() {
      this.disablePoursuivre = true
      this.showLoading = true
      this.updateStatistiqueQWTarif(1, 0)
      const dataParams = {
        composeData: this.getComposeDataTarif,
        produit: this.produitLocal,
        currentCourtierId: this.currentUser.courtier_user[0].courtier_id,
        currentUserId: this.currentUser.id,
        currentCourtierUserId: this.currentUser.courtier_user[0].id,
        tarificationRapideID: this.statistiqueObjet.id,
      }
      console.log('getComposeDataTarif',this.getComposeDataTarif)
      this.$http.post('quickwins/creeDevisAndClient', dataParams)
        .then(res => {
          if (res.data.success) {
            this.$router.push({
                name: 'projets-proposition',
                params: { id: res.data.data }
            })
          }
          this.showLoading = false
        })
        .catch(err => {
          this.showLoading = false
        this.disablePoursuivre = false
          console.error(err)
        }).finally(()  => {
          this.showLoading = false
        this.disablePoursuivre = false
        })
    },
    arrayProduit(produits) {
      let produitFiltre = []
      if (produits.length > 0) {
        produits.map(pr => {
          if(pr.tarif.length > 0) {
            pr.tarif.map(tf => {
              produitFiltre.push({
                produit: pr.produit,
                tarif: tf
              })
            })
          }
        })
        console.log(produitFiltre);
        produitFiltre.forEach(e => {
          e.produit.documentAssureur.sort((a, b) => {
            let fa = a.document.document_type.type.toLowerCase(),
              fb = b.document.document_type.type.toLowerCase();

            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }

            return 0;
          });
        })
        this.tarifsDataLocal = produitFiltre
        this.sorByPrice('UP')
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/pages/page-pricing.scss';

.offere_thClass th {
  background-color: #ebe9f1 !important;
  color: #4d25bc;
}
</style>

<style lang="scss" scoped>
#risque_prevoyance.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}

#risque_prevoyance > .sante_risque {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
}

#risque_prevoyance > .sante_risque.active {
  background-color: #4d25bc;
  color: #fff;
}

#risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}

#addprojectform .nav-tabs .nav-link {
  background-color: rgba(77, 37, 188, 0.08);
  color: #4d25bc;
}

#addprojectform .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #4d25bc;
}

.vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle .wizard-icon-container {
  background-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  border-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li a .checked {
  background-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li a .checked i {
  color: #fff !important;
}

.wizard-btn {
  background-color: #4d25bc !important;
  border-color: #4d25bc !important;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
  border-color: #4d25bc !important;
  color: #4d25bc !important;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn:hover {
  color: #fff !important;
  background-color: #4d25bc !important;
  transition: 0.3s;
  box-shadow: 0 8px 25px -8px #4d25bc;
}

.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked ~ .stepTitle {
  color: #4d25bc !important;
}

@media only screen and (max-width: 768px) {
  #risque_prevoyance > .sante_risque {
    max-width: calc(50% - 2rem);
  }
}

#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}

.padding_pricing {
  padding: 0rem 1.5rem 1.5rem;
}

.propositions .card-body {
  padding: 0 !important;
}

.propositions .card-header {
  background-color: #4d25bc;
  color: #fff;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
}

.propositions .card button {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}

.box-custom-text {
  color: #7353ca;
}
</style>
