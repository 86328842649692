<template>
    <!-- Satrt alert Régime -->
    <b-alert variant="danger" show v-show="regimeSelected == 'RÉGIME_SOCIAL' && !loiMadelin">
        <div class="alert-body">
        <p>
            <strong>Attention</strong> : Ne concerne que les assurés ayant liquidé leurs droits à la retraite
        </p>
        </div>
    </b-alert>
    <!-- End alert Régime -->
</template>

<script>
import { BAlert, BCol, BRow } from 'bootstrap-vue'
export default {
  components: {
    BAlert,
    BCol,
    BRow
  },
  props: {
    regimeSelected: {
      type: String,
      default: null,
    },
    loiMadelin: {
      type: Number,
      default: null,
    },
  },
}
</script>