<template>
  <div>
    <b-overlay :show="showLoading" no-wrap />
    <b-row>
      <b-col cols="8" class="mb-2">
        <h2 class="mb-0 text-uppercase font-weight-bolder">étape 1 : Choix du risque</h2>
        <h6>Sélectionnez le risque pour lequel vous souhaitez créer un projet</h6>
      </b-col>
      <b-col cols="4" class="text-right">
        <b-button variant="primary" size="lg" :disabled="disabled" @click="submitCheckedRisque"> Suivant </b-button>
      </b-col>
    </b-row>
    <b-row class="px-0">
      <!-- <b-col md="12" lg="3">
        <b-card class="mb-3">
          <h4 class="text-primary font-weight-bolder"><b>SANTÉ / PRÉVOYANCE</b></h4>
          <b-card-text> {{ getCountByRisque('Santé') }} solution(s) </b-card-text>
          <h4 class="text-primary font-weight-bolder"><b>IARD</b></h4>
          <b-card-text> {{ getCountByRisque('Rou') }} solution(s) </b-card-text>
        </b-card>
      </b-col> -->
      <b-col md="12" lg="12">
        <b-card>
          <b-row id="box-custom-modale-sm">
            <b-col cols="12" class="mb-2 mt-2">
              <h4 class="mb-0 text-uppercase text-primary font-weight-bolder text-underline">
                SANTÉ / PRÉVOYANCE : 
              </h4>
            </b-col>
          </b-row>      
          <b-row>
            <b-col md="4" @click="changeRisque('Santé TNS')">
              <label :class="[checkedRisque == 'Santé TNS' ? 'btn-primary' : '', 'btn-outline-primary-enabled']" class="btn btn-outline-primary box-custom-modale-child w-100 custom-label">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 3C4.239 3 2 5.216 2 7.95c0 2.207.875 7.445 9.488 12.74a.985.985 0 0 0 1.024 0C21.125 15.395 22 10.157 22 7.95C22 5.216 19.761 3 17 3s-5 3-5 3s-2.239-3-5-3Z" />
                  </svg>
                  SANTE TNS
                </span>
              </label>
              <p class="text-center font-weight-bolder">{{ getSpecificRisqueInfo('Santé TNS').assureurs_count }} {{ getSpecificRisqueInfo('Santé TNS').assureurs_count > 1 ? 'assureurs' : 'assureur' }} / {{ getSpecificRisqueInfo('Santé TNS').formules }} {{ getSpecificRisqueInfo('Santé TNS').formules > 1 ? 'formules' : 'formule' }}</p>

            </b-col>
            <b-col md="4" @click="changeRisque('Santé Séniors')">
              <label :class="[checkedRisque == 'Santé Séniors' ? 'btn-primary' : '', 'btn-outline-primary-enabled']" class="btn btn-outline-primary box-custom-modale-child w-100 custom-label">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 3C4.239 3 2 5.216 2 7.95c0 2.207.875 7.445 9.488 12.74a.985.985 0 0 0 1.024 0C21.125 15.395 22 10.157 22 7.95C22 5.216 19.761 3 17 3s-5 3-5 3s-2.239-3-5-3Z" />
                  </svg>
                  SANTE SENIOR
                </span>
              </label>
              <p class="text-center font-weight-bolder">{{ getSpecificRisqueInfo('Santé Séniors').assureurs_count }} {{ getSpecificRisqueInfo('Santé Séniors').assureurs_count > 1 ? 'assureurs' : 'assureur' }} / {{ getSpecificRisqueInfo('Santé Séniors').formules }} {{ getSpecificRisqueInfo('Santé Séniors').formules > 1 ? 'formules' : 'formule' }}</p>

            </b-col>     

            <b-col md="4" @click="changeRisque('Santé Animale')">
              <label :class="[checkedRisque == 'Santé Animale' ? 'btn-primary' : '', 'btn-outline-primary-enabled']" class="btn btn-outline-primary box-custom-modale-child w-100 custom-label">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 3C4.239 3 2 5.216 2 7.95c0 2.207.875 7.445 9.488 12.74a.985.985 0 0 0 1.024 0C21.125 15.395 22 10.157 22 7.95C22 5.216 19.761 3 17 3s-5 3-5 3s-2.239-3-5-3Z" />
                  </svg>
                  SANTE ANIMALE
                </span>
              </label>
              <p class="text-center font-weight-bolder">{{ getSpecificRisqueInfo('Santé Animale').assureurs_count }} {{ getSpecificRisqueInfo('Santé Animale').assureurs_count > 1 ? 'assureurs' : 'assureur' }} / {{ getSpecificRisqueInfo('Santé Animale').formules }} {{ getSpecificRisqueInfo('Santé Animale').formules > 1 ? 'formules' : 'formule' }}</p>

            </b-col>
          </b-row>
          <b-row id="box-custom-modale-sm">
            <b-col cols="12" class="mb-2 mt-2">
              <h4 class="mb-0 text-uppercase text-primary font-weight-bolder text-underline">
                EMPRUNTEURS : 
              </h4>
            </b-col>
          </b-row>
          <b-row>  
            <b-col md="4" @click="changeRisque('Emprunteur')">
              <label :class="[checkedRisque == 'Emprunteur' ? 'btn-primary' : '', 'btn-outline-primary-enabled']" class="btn btn-outline-primary box-custom-modale-child w-100 custom-label">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 3C4.239 3 2 5.216 2 7.95c0 2.207.875 7.445 9.488 12.74a.985.985 0 0 0 1.024 0C21.125 15.395 22 10.157 22 7.95C22 5.216 19.761 3 17 3s-5 3-5 3s-2.239-3-5-3Z"/></svg>
                            EMPRUNTEUR
                        </span>
                    </label>
                    <p class="text-center font-weight-bolder">{{ getSpecificRisqueInfo('Emprunteur').assureurs_count }} {{ getSpecificRisqueInfo('Emprunteur').assureurs_count > 1 ? 'assureurs' : 'assureur' }} / {{ getSpecificRisqueInfo('Emprunteur').formules }} {{ getSpecificRisqueInfo('Emprunteur').formules > 1 ? 'formules' : 'formule' }}</p>

            </b-col>  
          </b-row>
          <b-row id="box-custom-modale-sm">
            <b-col cols="12" class="mb-2 mt-2">
              <h4 class="mb-0 text-uppercase text-primary font-weight-bolder text-underline">
                IARD : 
              </h4>
            </b-col>
          </b-row>
          <b-row>  
            <b-col md="4" @click="changeRisque('2-Roues')">
              <label :class="[checkedRisque == '2-Roues' ? 'btn-primary' : '', 'btn-outline-primary-enabled']" class="btn btn-outline-primary box-custom-modale-child w-100 custom-label">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 2304 1280">
                    <path fill="currentColor" d="M2301 780q12 103-22 198.5t-99 163.5t-158.5 106t-196.5 31q-161-11-279.5-125T1411 880q-12-111 27.5-210.5T1557 499l-71-107q-96 80-151 194t-55 244q0 27-18.5 46.5T1216 896H891q-23 164-149 274t-294 110q-185 0-316.5-131.5T0 832t131.5-316.5T448 384q76 0 152 27l24-45Q501 256 320 256h-64q-26 0-45-19t-19-45t19-45t45-19h128q78 0 145 13.5T645.5 180t71.5 39.5t51 36.5h627l-85-128h-222q-30 0-49-22.5T1025 53q4-23 23-38t43-15h253q33 0 53 28l70 105l114-114q19-19 46-19h101q26 0 45 19t19 45v128q0 26-19 45t-45 19h-179l115 172q131-63 275-36q143 26 244 134.5T2301 780zM448 1152q115 0 203-72.5T762 896H448q-35 0-55-31q-18-32-1-63l147-277q-47-13-91-13q-132 0-226 94t-94 226t94 226t226 94zm1408 0q132 0 226-94t94-226t-94-226t-226-94q-60 0-121 24l174 260q15 23 10 49t-27 40q-15 11-36 11q-35 0-53-29l-174-260q-93 95-93 225q0 132 94 226t226 94z" />
                  </svg>
                  2 ROUES
                </span>
              </label>
              <p class="text-center font-weight-bolder">{{ getSpecificRisqueInfo('2-Roues').assureurs_count }} {{ getSpecificRisqueInfo('2-Roues').assureurs_count > 1 ? 'assureurs' : 'assureur' }} / {{ getSpecificRisqueInfo('2-Roues').formules }} {{ getSpecificRisqueInfo('2-Roues').formules > 1 ? 'formules' : 'formule' }}</p>
            </b-col>
          </b-row>
        </b-card>
        <b-row class="float-right">
          <b-col lg="4">
            <b-button variant="primary" size="lg" :disabled="disabled" @click="submitCheckedRisque"> Suivant </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BOverlay, BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BFormRadioGroup, BFormRadio, BFormCheckboxGroup, BTabs, BTab, BFormSelect, BTable, BTableSimple, BThead, BTr, BTh, BTd, BTbody, BButton, BCard, BCardText, BListGroup, BListGroupItem, BFormTextarea } from 'bootstrap-vue'
import { required, email } from '@validations'
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckboxGroup,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea
  },
  data() {
    return {
      checkedRisque: null,
      checkedBesoin: {},
      garantiesLocal: [],
      produitsGarantiesLocal: [],
      exigencesLocal: [],
      risquesWithChampsLocal: [],
      besoinsLocal: [],
      risquesChamps: [],
      besoinsLocalFiltre: {},
      emptyRisque: false,
      showLoading: false,
      activeStepLocal: [
        { indexStep: 0, active: 'choix' },
        { indexStep: 1, active: 'besoins' },
        { indexStep: 2, active: 'comparatif'}
      ],
      disabled: true,
      currentUser: JSON.parse(localStorage.getItem('userData')),
      risques_info:[]
    }
  },
  computed: {
    ...mapGetters(['getQWCheckedRisque'])
  },
  created(){
    this.getRisqueInfoAssureurFormules();
  },
  watch: {
    getQWCheckedRisque: {
      handler(val) {
        this.checkedRisque = val
        if(!val) this.disabled = true
      },
      immediate: true
    },
  },
  methods: {
    changeRisque(value) {
      // if (value != '2-Roues') {
      this.disabled = false
      this.checkedRisque = value
      // }
    },
    getCountByRisque(risque) {
      if (risque == 'Santé') return 2
      return 1
    },
    activeStep(value) {
      let arrayStep = []
      let step = _.find(this.activeStepLocal, function (as) {
        return as.active == value
      })
      this.activeStepLocal.map((st, key) => {
        if (st.indexStep <= step.indexStep) {
          arrayStep.push(st)
        }
      })
      return arrayStep
    },
    submitCheckedRisque() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.$store.commit('setQWCheckedRisque', this.checkedRisque)
      this.$store.commit('setQWStepTarificateur', { step: { indexStep: 1, active: 'besoins' } })
    },
    getRisqueInfoAssureurFormules(){
        if(!this.currentUser.risques_info || this.currentUser.risques_info.length === 0){
          this.$http('risques/getInfoAssureurFormules').then((res)=>{
            this.risques_info = res.data
            this.currentUser.risques_info = this.risques_info
            localStorage.setItem('userData', JSON.stringify(this.currentUser))
        })
        }
        else{
          this.risques_info = this.currentUser.risques_info
        }

      },
      getSpecificRisqueInfo(risque){
        return this.risques_info.find(r => r.risque_label == risque);
      }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/pages/page-pricing.scss';

#risque_prevoyance.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
  width: 100%;
}
#risque_prevoyance > .sante_risque {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
}
#risque_prevoyance > .sante_risque.active {
  background-color: #4d25bc;
  color: #fff;
}
#risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}
// div#risque_prevoyance > .btn-outline-primary.disabled, [dir] .btn-outline-primary:disabled {
//     background-color: #cdcdcd;
// }

.custom-disabled {
  background-color: #f1efef;
}
</style>

<style lang="scss" scoped>
#addprojectform .nav-tabs .nav-link {
  background-color: rgba(77, 37, 188, 0.08);
  color: #4d25bc;
}
#addprojectform .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #4d25bc;
}
.vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle .wizard-icon-container {
  background-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  border-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .checked {
  background-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .checked i {
  color: #fff !important;
}
.wizard-btn {
  background-color: #4d25bc !important;
  border-color: #4d25bc !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
  border-color: #4d25bc !important;
  color: #4d25bc !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn:hover {
  color: #fff !important;
  background-color: #4d25bc !important;
  transition: 0.3s;
  box-shadow: 0 8px 25px -8px #4d25bc;
}
.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked ~ .stepTitle {
  color: #4d25bc !important;
}
@media only screen and (max-width: 768px) {
  #risque_prevoyance > .sante_risque {
    max-width: calc(50% - 2rem);
  }
}

#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}
.offere_thClass th {
  background-color: #4d25bc !important;
  color: #fff;
}
.padding_pricing {
  padding: 0rem 1.5rem 1.5rem;
}
.propositions .card-body {
  padding: 0 !important;
}
.propositions .card-header {
  background-color: #4d25bc;
  color: #fff;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
}
.propositions .card button {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}

#box-custom-modale > .box-custom-modale-child {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
  background-color: #fff;
}

#box-custom-modale-sm > .box-custom-modale-child {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
  background-color: #fff;
}
#box-custom-modale > .box-custom-modale-child.active {
  background-color: #4d25bc;
  color: #fff;
}
#box-custom-modale > .box-custom-modale-child.active {
  background-color: #4d25bc;
  color: #fff;
}
#box-custom-modale-sm .btn-outline-primary-enabled:hover {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}

#box-custom-modale-sm .btn-outline-primary-disabled:hover {
  background-color: #f1efef;
  transition: 0.4s;
}

#box-custom-modale-sm .btn-outline-primary-disabled {
  border: none !important;
}
.custom-label {
  margin: 1rem;
  padding: 25px 15px;
}
</style>
