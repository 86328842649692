<template>
    <div>
        <tarifs-sante-tns v-if="getRisqueStatut('SANTETNS')" />
        <tarifs-sante-senior v-else-if="getRisqueStatut('SANTESENIORS')" />
        <tarifs-deux-roues v-else-if="getRisqueStatut('2-ROUES')" />
        <tarifs-emprunteur v-else-if="getRisqueStatut('EMPRUNTEUR')" />
    </div>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { mapGetters } from 'vuex'
import tarifsSanteTns from './TarifsSanteTns.vue'
import tarifsSanteSenior from './TarifsSanteSenior.vue'
import tarifsDeuxRoues from './Tarifs2Roues.vue'
import tarifsEmprunteur from './TarifsEmprunteur.vue'

export default {
  components: {
    tarifsSanteTns,
    tarifsSanteSenior,
    tarifsDeuxRoues,
    tarifsEmprunteur
  },
  computed: {
    ...mapGetters(['getQWCheckedRisque'])
  },
  methods: {
    getRisqueStatut(value) {
      return (value && this.getQWCheckedRisque) ? this.formatText(this.getQWCheckedRisque) == value : ''
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";

.offere_thClass th {
  background-color: #ebe9f1 !important;
  color: #4d25bc;
}
</style>
<style lang="scss" scoped>
#risque_prevoyance.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}

#risque_prevoyance>.sante_risque {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
}

#risque_prevoyance>.sante_risque.active {
  background-color: #4d25bc;
  color: #fff;
}

#risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}

#addprojectform .nav-tabs .nav-link {
  background-color: rgba(77, 37, 188, 0.08);
  color: #4d25bc;
}

#addprojectform .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #4d25bc;
}

.vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle .wizard-icon-container {
  background-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  border-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li a .checked {
  background-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li a .checked i {
  color: #fff !important;
}

.wizard-btn {
  background-color: #4d25bc !important;
  border-color: #4d25bc !important;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
  border-color: #4d25bc !important;
  color: #4d25bc !important;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn:hover {
  color: #fff !important;
  background-color: #4d25bc !important;
  transition: 0.3s;
  box-shadow: 0 8px 25px -8px #4d25bc;
}

.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked~.stepTitle {
  color: #4d25bc !important;
}

@media only screen and (max-width: 768px) {
  #risque_prevoyance>.sante_risque {
    max-width: calc(50% - 2rem);
  }
}

#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}

.padding_pricing {
  padding: 0rem 1.5rem 1.5rem;
}

.propositions .card-body {
  padding: 0 !important;
}

.propositions .card-header {
  background-color: #4d25bc;
  color: #fff;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
}

.propositions .card button {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}

.box-custom-text {
  color: #7353ca;
}
</style>