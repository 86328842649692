<template>
  <div>
    <b-overlay :show="showLoading" no-wrap />
    <b-row v-if="risqueLocal.length > 0">
      <b-col class="mb-2" cols="12">
        <h2 class="mb-0 text-uppercase font-weight-bolder">
          étape 3 : Comparaison des offres
        </h2>
        <b-button v-if="checkIfHasRisqueLocal && hasProduitAutoriser" variant="primary" class="float-right" size="lg" :disabled="disablePoursuivre"
          @click="creeDevis()">Poursuivre vers la création d'un devis</b-button>
      </b-col>
      <b-col lg="12">
        <b-button class="float-left my-2" size="lg" variant="primary" @click="backStep">
          Précédent
        </b-button>
        
        <b-button v-if="checkIfHasRisqueLocal && !hasProduitAutoriser" variant="primary" class="float-right my-2" size="lg" @click="redirecToDemandeCode()">Demande d'ouverture de code</b-button>
      </b-col>
      <transition name="fade">
        <b-col v-if="showCompareTable" cols="12">
          <div v-for="(rl, index) in risqueLocal" :key="index + 'content'" class="mb-5">
            <b-table-simple v-for="(pr, val) in rl.produitsFiltred" :key="val + 'produits'" bordered caption-top
              class="mb-4" hover responsive>
              <b-thead class="offere_thClass">
                <b-tr>
                  <b-th class="text-center" style="width: 15%;">
                    produit
                  </b-th>
                  <b-th class="text-center" style="width: 15%;">
                    documents
                  </b-th>
                  <b-th class="text-center" style="width: 25%;">
                    Garanties et franchises
                  </b-th>
                  <b-th class="text-center" style="width: 25%;">
                    Options
                  </b-th>
                  <b-th class="text-center" style="width: 20%;">
                    prix
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody class="offere_tdClass">
                <b-td class="text-center">
                  <p class="box-custom-text">
                    {{ pr.produit.produit_nom }}
                  </p>
                  <template v-if="pr.produit.produit_ws_nom == 'LGECRD'">
                    <b-img :src="require('@/assets/images/logo/assureur/Harmonie-assurance.png')" alt="logo" class="mb-1"
                      height="auto" width="100px" />
                  </template>
                  <template v-else-if="pr.produit.produit_ws_nom == 'ALTUS'">
                    <b-img :src="require('@/assets/images/logo/assureur/mutlog.png')" alt="logo" class="mb-1"
                      height="auto" width="100px" />
                  </template>
                  <p class="box-custom-text">
                    {{ pr.produit.assureur.nom_com_assureur }}
                  </p>
                </b-td>
                <b-td>
                  <div v-for="(pda, compt) in pr.produit.document_assureur" :key="compt + 'pda'">
                    <span v-if="pda.document" class="text-nowrap cursor-pointer"
                      @click="showFile(pda.document.id, pda.document.document_type.type)">
                      <feather-icon color="#bf2626" icon="FileIcon" size="14" style="margin-bottom: 4px;" />
                      <span class="text-nowrap">{{ pda.document.document_type.type }}</span>
                    </span>
                  </div>
                </b-td>
                <b-td class="align-top">
                  <div v-for="(DTBP, indexDTBP) in $_.orderBy(pr.tarif.detailTarifByPret,'is_assure', 'desc')"
                    :key="indexDTBP + ' - GARANTIES ET FRANCHISES'" class="py-1">
                    <b-list-group v-for="(garantieEtFranchise, indexgarantie) in DTBP.garanties_et_franchises"
                      :key="indexDTBP + ' - ' + indexgarantie + ' - GARANTIES ET FRANCHISES'">
                      <b-list-group-item class="border-0 p-0 no-hover-bg py-0">
                        <span v-if="garantieEtFranchise.isParent" :style="garantieEtFranchise.style"> {{
                          garantieEtFranchise.value }} </span>
                        <b-list-group v-else-if="!garantieEtFranchise.isParent && garantieEtFranchise.isShowing"
                          :style="garantieEtFranchise.style">
                          <b-list-group-item class="border-0 no-hover-bg py-0">
                            <div class="d-flex bd-highlight align-items-center">
                              <div class="w-75 bd-highlight">
                                {{ garantieEtFranchise.key }}
                              </div>
                              <div class="flex-shrink-1 bd-highlight text-left">
                                {{ garantieEtFranchise.value }}
                              </div>
                            </div>
                          </b-list-group-item>
                        </b-list-group>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </b-td>
                <b-td class="align-top">
                  <div v-for="(DTBP, indexDTBP) in $_.orderBy(pr.tarif.detailTarifByPret, 'is_assure', 'desc')" :key="indexDTBP + ' - OPTIONS'"
                    class="py-1 align-items-start">
                    <b-list-group v-for="(garantieEtFranchise, indexgarantie) in DTBP.options"
                      :key="indexDTBP + ' - ' + indexgarantie + ' - OPTIONS'">
                      <b-list-group-item class="border-0 p-0 no-hover-bg py-0">
                        <span v-if="garantieEtFranchise.isParent" :style="garantieEtFranchise.style"> {{
                          garantieEtFranchise.value }} </span>
                        <b-list-group v-else :style="garantieEtFranchise.style">
                          <b-list-group-item class="border-0 no-hover-bg py-0">
                            <div class="d-flex bd-highlight align-items-center">
                              <div class="w-100 bd-highlight">
                                {{ garantieEtFranchise.key }}
                              </div>
                              <div class="flex-shrink-1 bd-highlight">
                                <feather-icon :icon="garantieEtFranchise.value" size="16"
                                  :color="garantieEtFranchise.style" />
                              </div>
                            </div>
                          </b-list-group-item>
                        </b-list-group>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </b-td>
                <b-td>
                  <div class="text-center">
                    <div class="px-0">
                      <h4>{{ pr.tarif.formule_commercial }}</h4>
                      <div class="annual-plan">
                        <div class="plan-price mt-2">
                          <div class="pricing-basic-value font-weight-bolder text-primary mb-1"
                            v-for="(total, keyTotal, indexTotal) in pr.tarif.totalBase" :key="indexTotal + ' - total'">
                            <sup class="font-weight-bold text-primary">€ </sup>{{ Number(total).toFixed(2) + ' /an (' +
                              keyTotal + ')' }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6> {{ pr.tarif.formule =='CI' && pr.produit.assureur_id == '4' ? "Cotisation annualisée (1ère année)" : pr.tarif.formule =='CI' && pr.produit.assureur_id == '2632' ?  "cotisation fixe annuelle sur l'ensemble du prêt" : pr.tarif.formule =='CRD' && pr.produit.assureur_id == '2632'? "Cotisation annuelle moyenne calculée sur le montant total du prêt" : pr.tarif.formule =='CRD' && pr.produit.assureur_id == '4' ? "Cotisation annualisée (1ère année)" : "" }} </h6> 
                  </div>
                </b-td>
              </b-tbody>
              <b-tfoot class="offere_tdClass">
                <b-tr>
                  <b-td colspan="7">
                    <app-collapse id="collapse-besoin" type="margin">
                      <app-collapse-item ref="tab-collapse-item-0" :is-visible="false"
                        title="<h4 class='title-custom-wizard'><u>Détails de l’offre</u></h4>">
                        <b-row>
                          <b-col cols="12" class="mb-2" style="text-align: justify;">
                            <b-table-simple striped hover offere_thClass text-center>
                              <template v-slot:default>
                                <b-thead class="offere_thClass text-center">
                                  <b-tr v-if = "pr.produit.assureur_id == '4'">
                                    <b-th>ASSURE</b-th>
                                    <b-th>PRET</b-th>
                                    <b-th>DECES/PTIA</b-th>
                                    <b-th>ITT/IPT/IPP</b-th>
                                    <b-th>MNO</b-th>
                                    <b-th>EXO</b-th>
                                    <b-th>TAEA</b-th>
                                    <b-th>BUDGET</b-th>
                                    <b-th>BUDGET COUVERTURE DE PRET SUR 8 ANS</b-th>
                                  </b-tr>
                                  <b-tr v-else-if = "pr.produit.assureur_id == '2632'">
                                      <b-th>ASSURE</b-th>
                                      <b-th>PRET</b-th>
                                      <b-th>DECES/PTIA</b-th>
                                      <b-th>ITT/IPT</b-th>
                                      <b-th>IPP</b-th>
                                      <b-th>MNO</b-th>
                                      <b-th>ACC</b-th>
                                      <b-th>PEI</b-th>
                                      <b-th>TAEA</b-th>
                                      <b-th>BUDGET</b-th>
                                      <b-th>BUDGET COUVERTURE DE PRET SUR 8 ANS</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody v-if = "pr.produit.assureur_id == '4'">
                                  <template v-for="(group, indexDTBP) in mergedData($_.orderBy(pr.tarif.detailTarifByPret, 'is_assure', 'desc'))">
                                    <b-tr  v-for="(detail, idx) in group.detail" :key="indexDTBP + ' - ' + idx + ' - detail tarif par pret'">
                                      <b-td v-if="idx === 0" :rowspan="group.detail.length">{{ group.assure }}</b-td>
                                      <b-td>{{ detail.pret }}</b-td>
                                      <b-td>{{ detail.deces_ptia }}</b-td>
                                      <b-td>{{ detail.itt_ipt }}</b-td>
                                      <b-td>{{ detail.mno }}</b-td>
                                      <b-td>-</b-td>
                                      <b-td>{{ detail.taea }}</b-td>
                                      <b-td v-if="idx === 0" :rowspan="group.detail.length">{{ Number(group.budget).toFixed(2) }}</b-td>
                                      <b-td v-if="idx === 0" :rowspan="group.detail.length">{{ Number(group.budget_couverture_de_pret_sur_8_ans).toFixed(2) }}</b-td>
                                    </b-tr>
                                  </template>
                                </b-tbody>
                                <b-tbody  v-if = "pr.produit.assureur_id == '2632'">
                                  <template 
                                    v-for="(group, indexDTBP) in mergedData($_.orderBy(pr.tarif.detailTarifByPret, 'is_assure', 'desc'))">
                                    <b-tr v-for="(detail, idx) in group.detail"
                                      :key="indexDTBP + ' - ' + idx + ' - detail tarif par pret'">
                                      <b-td v-if="idx === 0" :rowspan="group.detail.length">{{ group.assure }}</b-td>
                                      <b-td>{{ detail.pret }}</b-td>
                                      <b-td>{{ detail.deces_ptia }}</b-td>
                                      <b-td>{{ detail.itt_ipt }}</b-td>
                                      <b-td>{{ detail.ipp }}</b-td>
                                      <b-td>{{ detail.mno }}</b-td>
                                      <b-td>{{ detail.acc }}</b-td>
                                      <b-td>{{ detail.pei }}</b-td>
                                      <b-td>{{ detail.taea }}</b-td>
                                      <b-td v-if="idx === 0" :rowspan="group.detail.length">{{
                                        Number(group.budget).toFixed(2) }}</b-td>
                                      <b-td v-if="idx === 0" :rowspan="group.detail.length">{{
                                        Number(group.budget_couverture_de_pret_sur_8_ans).toFixed(2) }}</b-td>
                                    </b-tr>
                                  </template>
                                </b-tbody>
                              </template>
                            </b-table-simple>
                          </b-col>
                        </b-row>
                      </app-collapse-item>
                    </app-collapse>
                  </b-td>
                </b-tr>
              </b-tfoot>
            </b-table-simple>
          </div>
        </b-col>
      </transition>
    </b-row>
    <b-row v-else>
      <b-col lg="12">
        <b-alert variant="warning" show v-show="getAssureursRisque(risqueLocal[0].produits)" class="mt-1">
          <div class="alert-body">
            <p v-html="getAssureursRisque(risqueLocal[0].produits)"></p>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col  lg="12">
        <app-collapse id="collapse-besoin" :is-visible="true"  type="margin">
          <app-collapse-item ref="tab-collapse-item-0"  :is-visible="true" title="<h4 class='title-custom-wizard'><u>Lexique<u></h4>">
          <ul>
            <li> Les montants affichés au niveau des garanties, options et budgets (hors budget sur 8 ans) portent sur la durée total du prêt.</li>
            <li><feather-icon icon="MinusIcon" size="24" /> : Garantie non envisagée</li>
            <li><feather-icon icon="CheckIcon" size="24" color="green"/> : Soit la garantie est envisagée et proposée soit la garantie est incluse</li>
            <li><feather-icon icon="XIcon" size="24" color="red"/> : La garantie envisagée n’est pas proposée (ou ne l’est pas pour cet assuré/ce projet)</li>
          </ul>
            <p>Chez Harmonie mutuelle, les options (EXO, DOS/PSY) sont sélectionnées par assuré (pas de distinction par prêt). Si une option est demandée sur un prêt, elle sera demandée sur les deux prêts.</p>
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>
    <b-row  style="margin-top:50px;">
      <b-col lg="12">
        <b-button class="float-left" size="lg" variant="primary" @click="backStep">
          Précédent
        </b-button>
        <b-button v-if="checkIfHasRisqueLocal && hasProduitAutoriser" variant="primary" class="float-right" size="lg" :disabled="disablePoursuivre"
          @click="creeDevis()">Poursuivre vers la création d'un devis</b-button>
        <b-button v-if="checkIfHasRisqueLocal && !hasProduitAutoriser"  variant="primary" class="float-right my-2" size="lg" @click="redirecToDemandeCode()">Demande d'ouverture de code</b-button>


      </b-col>
    </b-row>
    <b-modal id="modal-display-document" ref="modal-display-document"
      :size="document.extensionDocument === 'PDF' ? 'xl' : 'sm'" :title="document.name" ok-only ok-title="Fermer"
      ok-variant="outline-secondary" @ok="clearDocumentDisplay">
      <form ref="form" :style="{ height: document.extensionDocument === 'PDF' ? '80vh' : 'auto', overflow: 'hidden' }">
        <b-img v-if="document.extensionDocument === 'IMAGE'" :alt="document.name" :src="document.base64" fluid />
        <iframe v-else-if="document.extensionDocument === 'PDF'" :src="document.base64" height="100%" width="100%"
          :style="hideToolbar()" />
      </form>
    </b-modal>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { mapGetters } from 'vuex'
import {
  BButton,
  BCol,
  BImg,
  BModal,
  BOverlay,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTh,
  BThead,
  BTr,
  VBTooltip,
  BListGroup,
  BListGroupItem,
  BTfoot
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { selectRisqueAndProduct, updateProduitTarif } from '../../../../shared/utils/TarifCalcul'

export default {
  components: {
    BOverlay,
    BRow,
    BImg,
    BCol,
    BListGroup,
    BListGroupItem,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    AppCollapseItem,
    AppCollapse,
    BModal,
    BTfoot,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return this.initState()
  },
  computed: {
    ...mapGetters(['getQWSelectedRisque']),
    ...mapGetters(['getQWObjet']),
    ...mapGetters(['getQWItemsRisque']),
    ...mapGetters(['getProduitTarificateur']),
    ...mapGetters(['getQWHasProduitAutoriser']),
    ...mapGetters(['getQWCheckedRisque']),
    ...mapGetters(['getQWProspect']),
    ...mapGetters(["getQWEnfants"]),
    ...mapGetters(["getQWConjoint"]),
    ...mapGetters(["getQWAmis"]),
    ...mapGetters(["getQWParents"]),
    checkIfHasRisqueLocal() {
      if (this.risqueLocal.length > 0 && this.risqueLocal[0].produitsFiltred.length > 0) {
        return true
      }
      return false
    },
  },
  watch: {
    getQWObjet: {
      handler(val) {
        this.objet = val
      },
    },
    getQWProspect: {
      handler(val) {
        this.assure = val
      }
    },
    getQWConjoint: {
      handler(val) {
        this.conjoint = val
      }
    },
    getQWEnfants: {
      handler(val) {
        this.enfants = val
      }
    },
    getQWAmis: {
      handler(val) {
        this.amis = val
      }
    },
    getQWParents: {
      handler(val) {
        this.parents = val
      }
    },
    getQWItemsRisque: {
      handler(val) {
        if (val.length > 0) {
          this.risqueLocal = val
          this.arrayProduit()
          this.statistiqueObjet = this.initState().statistiqueObjet
          this.statistiqueQWTarif()
          this.filterDocumentByFormule();

        }
      },
    },
    getProduitTarificateur: {
      handler(val) {
        this.produitLocal = val.produit
        this.initProduitAutoriser(this.produitLocal)
      },
    },
    hasProduitAutoriser: {
      immediate: true,
      handler(val) {
        this.$store.commit('setQWHasProduitAutoriser', { hasProduitAutoriser: val })
      },
    },
    getQWCheckedRisque: {
      immediate: true,
      handler(val) {
        this.checkedRisque = val
      },
    }
  },
  methods: {
    mergedData(detailTarifByPret) {
      /// Group and sum the data by the "assure" column
      const groupedData = {}

      detailTarifByPret.forEach(item => {
        if (!groupedData[item.assure]) {
          groupedData[item.assure] = {
            assure: item.assure,
            budget: 0,
            budget_couverture_de_pret_sur_8_ans: 0,
            detail: []
          }
        }

        const group = groupedData[item.assure]
        if (item.budget == '-') { item.budget = 0 }
        if (item.budget_couverture_de_pret_sur_8_ans == '-') { item.budget_couverture_de_pret_sur_8_ans = 0 }

        group.budget += Number(item.budget) || 0
        group.budget_couverture_de_pret_sur_8_ans += Number(item.budget_couverture_de_pret_sur_8_ans) || 0

        group.detail.push(item)
      })

      return Object.values(groupedData)
    },
    filterDocumentByFormule() {

      this.risqueLocal[0].produitsFiltred.forEach(function (produitFiltred) {

        if (produitFiltred.tarif.formule == "CRD") {
          produitFiltred.produit.document_assureur = produitFiltred.produit.document_assureur.filter(function (d) {
            return produitFiltred.tarif.formule !== "CRD" || d.document.nom_custom !== "Conditions générales LGE CI";
          });
          produitFiltred.produit.document_assureur = produitFiltred.produit.document_assureur.filter(function (d) {
            return produitFiltred.tarif.formule !== "CRD" || d.document.nom_custom !== "IPID LGE CI";
          });
        }
        else if (produitFiltred.tarif.formule == "CI") {
          produitFiltred.produit.document_assureur = produitFiltred.produit.document_assureur.filter(function (d) {
            return produitFiltred.tarif.formule !== "CI" || d.document.nom_custom !== "Conditions générales LGE CRD";
          });
          produitFiltred.produit.document_assureur = produitFiltred.produit.document_assureur.filter(function (d) {
            return produitFiltred.tarif.formule !== "CI" || d.document.nom_custom !== "IPID LGE CRD";
          });
        }
      });
    },
    creeDevis() {
      this.disablePoursuivre = true
      this.updateStatistiqueQWTarif(1, 0)
      this.addProspectWithDevis()
    },
    async addProspectWithDevis() {
      this.showLoading = true
      const prodpectData = {
        civilite: this.assure.civilite,
        situation_familiale: this.assure.situation_familiale,
        statut: this.assure.statut,
        date_naissance: this.assure.date_naissance,
        code_postal:this.assure.moyen_contact.code_postal,
        ville_id:this.assure.moyen_contact.ville_id,
      }
      
      this.$http
        .post('prospect/saveorupdateQWPersonnePhysique', prodpectData)
        .then(res => {
          if (res.status) {
            const prospectData = res.data.prospect
            this.addEnfants(prospectData)
            this.addConjoint(prospectData)
            this.addAmis(prospectData)
            this.addParents(prospectData)
            this.recordDevis(prospectData)
          }
        })
        .catch(() => {
          this.showLoading = false
          this.disablePoursuivre = false
        })
    },
    addEnfants(prospect) {
      let enfants = this.enfants.filter(enfant => enfant.date_naissance != null)
      console.log(enfants);
      if(enfants.length > 0) {
        var data = {
          prospect: prospect,
          connexite: enfants
        }
        this.$http
        .post(`prospect/saveQWConnexitePhysique`, data)
        .then((r) => {
          if (r.status) {
            
          }
        })
        .catch((e) => {
          this.disablePoursuivre = false
        });
      }
    },
    getAssureursRisque(produits) {
      var innerHTML = null
      var assureurs = produits.reduce((assureurs, pr) => {
        if (pr.tarif <= 0) {
          assureurs.push(pr.assureur.nom_com_assureur);
        }
        return assureurs;
      }, []);

      if(assureurs.length > 0)
        innerHTML = `Aucun tarif disponible pour ${assureurs.length == 1 ? 'la compagnie' : 'les compagnies'} : <strong>${assureurs.join(", ")}</strong>`
      
      return innerHTML
    }, 
    addAmis(prospect) {
      let amis = this.amis.filter(ami => ami.date_naissance != null)
      console.log(amis);
      if(amis.length > 0) {
        var data = {
          prospect: prospect,
          connexite: amis
        }
        this.$http
        .post(`prospect/saveQWConnexitePhysique`, data)
        .then((r) => {
          if (r.status) {
            
          }
        })
        .catch((e) => {
          this.disablePoursuivre = false
        });
      }
    },
    addParents(prospect) {
      let parents = this.parents.filter(parent => parent.date_naissance != null)
      console.log(parents);
      if(parents.length > 0) {
        var data = {
          prospect: prospect,
          connexite: parents
        }
        this.$http
        .post(`prospect/saveQWConnexitePhysique`, data)
        .then((r) => {
          if (r.status) {
            
          }
        })
        .catch((e) => {
          this.disablePoursuivre = false
        });
      }
    },
    async addConjoint(prospect) {
      if(this.conjoint.date_naissance) {
        var data = {
          prospect: prospect,
          connexite: [this.conjoint]
        }
        this.$http
        .post(`prospect/saveQWConnexitePhysique`, data)
        .then((r) => {
          if (r.status) {
            
          }
        })
        .catch((e) => {
          this.disablePoursuivre = false

        });
      }
    },
    async recordDevis(prospect) {
      let etudeData = {}
      let etudeNom = `Devis ${this.checkedRisque} Pour Tarificateur`
      etudeData.prospect = prospect
      etudeData.etude = etudeNom

      await this.$http.post('portefeuilles/initEtude', etudeData).then(res => {
        this.getEtude.etudeID = res.data
      }).catch(e => {
        console.log(e)
        this.disablePoursuivre = false
      })
      this.getEtude.etudeNom = etudeNom

      await this.$http.get(`portefeuilles/getEtudeSingle/${this.getEtude.etudeID}`).then(res => {
        this.getEtude.etude_no = res.data.data.etude_no
        this.getEtude.created_at = res.data.data.created_at
      }).catch(e => {
        console.log(e)
        this.disablePoursuivre = false
      })

      const data = {
        risques: [{
          risque: {
            id: this.produitLocal.risque_id,
            label: 'Emprunteur',
            type: 'Prévoyance et santé'
          },
          devis: null,
          objets: { objet_emprunteur: this.objet },
        }],
        prospect,
        etudeID: this.getEtude.etudeID,
        tarificationRapideID: this.statistiqueObjet.id
      }

      await this.$http.post('quickwins/getQWObjectPortefeuille', data).then(() => {
        this.$router.push({
          name: 'projets-proposition',
          params: { id: this.getEtude.etudeID }
        })
        this.showLoading = false
      }).catch(() => {
        this.showLoading = false
        this.disablePoursuivre = false
      })
    },
    statistiqueQWTarif() {
      this.statistiqueObjet.courtier_fk = this.currentUser.courtier_user[0].courtier_id
      this.statistiqueObjet.user_fk = this.currentUser.courtier_user[0].user_id
      this.statistiqueObjet.risque_fk = this.produitLocal.risque_id
      this.statistiqueObjet.risque_nom  = this.checkedRisque
      this.statistiqueObjet.tarifs      = this.risqueLocal[0].produitsFiltred.sort((a, b) => a.tarif.tarif - b.tarif.tarif)
      this.statistiqueObjet.qw_tarif    = {
        "assure"    : this.assure,
        "objet"     : this.objet
      } 

      this.$http.post('/tarification-rapide/addOrUpdateTarififcationRapide', this.statistiqueObjet).then(res => {
        if (res.data.success) {
          this.statistiqueUpdated = false
          this.statistiqueObjet.id = res.data.data.id
        }
      })
    },
    updateStatistiqueQWTarif(marketplace, demandeCode) {
      this.showLoading = true
      if (!this.statistiqueUpdated) {
        if (this.checkIfHasRisqueLocal) {
          if (this.hasProduitAutoriser && marketplace) this.statistiqueObjet.marketplace = 1
          else if (demandeCode) {
            this.statistiqueObjet.demandeCode = 1
          }

          this.$http.post('/tarification-rapide/addOrUpdateTarififcationRapide', this.statistiqueObjet).then(res => {
            if (res.data.success) {
              this.statistiqueUpdated = true
            }
          }).catch((e) => {
            this.showLoading = false
            this.disablePoursuivre = false
          });
        }
      }
      this.showLoading = false
    },
    initState() {
      return {
        disablePoursuivre: false,
        getEtude: {
          etudeID: null,
          etudeNo: null,
          etude_n: null,
          created: null
        },
        assure: null,
        hasProduitAutoriser: false,
        produitLocal: {
          produit_id: '',
          risque_id: '',
          produit_nom: '',
          nom_assureur: '',
        },
        objet: null,
        statistiqueObjet: {
          id: null,
          courtier_fk: null,
          user_fk: null,
          risque_fk: null,
          marketplace: 0,
          demandeCode: 0,
          risque_nom: null,
          tarifs: [],
          qw_tarif: {}
        },
        currentUser: JSON.parse(localStorage.getItem('userData')),
        checkerExplicationOptions: [],
        optionsFrequencePaiement: [
          { value: 'MOIS', text: 'Mensuelle' },
          { value: 'ANNEE', text: 'Annuelle' },
        ],
        risqueLocal: [],
        produitGarantieNiveau: [],
        tarif: Math.floor(Math.random() * 100) + 60,
        loading: false,
        indexLocal: 0,
        test: null,
        indexRisque: 0,
        hasProduct: false,
        showCompareTable: true,
        showLoading: false,
        enfants:[],
        amis:[],
        conjoint:null,
        parents:[],
        document: {
          base64: null,
          name: null,
          nameToDownload: null,
          extensionDocument: null,
        },
        disablePoursuivre: false

      }
    },
    initProduitAutoriser(produit) {
      const produitsAutorised = this.currentUser.courtier_user[0].courtier.produits_autorise
      if (produitsAutorised.length > 0) {
        const result = produitsAutorised.find(p => p.produit_id === produit.produit_id)
        if (result !== undefined) {
          this.hasProduitAutoriser = true
        } else {
          this.hasProduitAutoriser = false
        }
      } else {
        this.hasProduitAutoriser = false
      }
    },
    getLibelleRAFR(optionsCompatibles) {
      const blocLibelleRAFR = []
      if (optionsCompatibles.length > 0) {
        optionsCompatibles.forEach(option => {
          console.log()
          if (option.RAFRx !== undefined) blocLibelleRAFR.push(`Vol/Incendie - ${option.RAFRx.libelleGarantie2} ${this.getlibraf3(optionsCompatibles)}`)
          if (option.RAFRy !== undefined) blocLibelleRAFR.push(`Dommages - ${option.RAFRy.libelleGarantie2} ${this.getlibraf4(optionsCompatibles)}`)
        })
      }
      return blocLibelleRAFR.length > 0 ? blocLibelleRAFR.join('</br>') : null
    },
    getlibraf3(optionsCompatibles) {
      let blocLibelleRAFR = ''
      if (optionsCompatibles.length > 0) {
        optionsCompatibles.forEach(option => {
          if (option.RAFR3 !== undefined) blocLibelleRAFR = `et, pour un rachat partiel, ${option.RAFR3.libelleGarantie2}`
        })
      }
      return blocLibelleRAFR
    },
    getlibraf4(optionsCompatibles) {
      let blocLibelleRAFR = ''
      if (optionsCompatibles.length > 0) {
        optionsCompatibles.forEach(option => {
          if (option.RAFR4 !== undefined) blocLibelleRAFR = `et, pour un rachat partiel, ${option.RAFR4.libelleGarantie2}`
        })
      }
      return blocLibelleRAFR
    },
    checkRAFR(produitGarantieNiveaux, optionsCompatibles) {
      let produitGarantieNiveauxChanged = []
      let RAFR3 = false
      let RAFR4 = false
      if (optionsCompatibles.length > 0) {
        optionsCompatibles.forEach(option => {
          if (option.RAFR3) {
            RAFR3 = true
          } else if (option.RAFR4) {
            RAFR4 = true
          }
        })

        if (!RAFR3 && !RAFR4) {
          produitGarantieNiveauxChanged = produitGarantieNiveaux.filter(pgn => pgn.libelle !== 'RAFR3' && pgn.libelle !== 'RAFR4')
        } else if (!RAFR3 && RAFR4) {
          produitGarantieNiveauxChanged = produitGarantieNiveaux.filter(pgn => pgn.libelle !== 'RAFR3')
        } else if (RAFR3 && !RAFR4) {
          produitGarantieNiveauxChanged = produitGarantieNiveaux.filter(pgn => pgn.libelle !== 'RAFR4')
        } else {
          produitGarantieNiveauxChanged = produitGarantieNiveaux
        }
      }
      return produitGarantieNiveauxChanged
    },
    getGarantieProduit(produitGarantieId, produitgarantiesniveaux) {
      const found = produitgarantiesniveaux.find(pg => pg.produit_garantie_id === produitGarantieId)
      return found ? found.libelle_commercial : ''
    },
    getGarantieProduitCommercial(garantie, produitGarantie) {
      const found = produitGarantie.find(pg => pg.garantie_id === garantie)
      return found ? found.libelle_comm : ''
    },
    hasGarantieNiveau(gn, options) {
      const tmpOption = _.find(options.optionsCompatibles, (v, key) => key === gn)
      return !!tmpOption
    },
    hasGarantieProduitNiveau(produitGarantieId, produitgarantiesniveaux, options, ifFranchise) {
      const listFranchisesChecker = ['RAFR2', 'RAFR1', 'RAFRx', 'RAFRy', 'RAFR3', 'RAFR4']
      let found
      if (ifFranchise) {
        found = produitgarantiesniveaux.find(pg => pg.produit_garantie_id === produitGarantieId && listFranchisesChecker.includes(pg.libelle))
      } else {
        found = produitgarantiesniveaux.find(pg => {
          if (listFranchisesChecker.includes(pg.libelle)) {
            return false
          }
          return pg.produit_garantie_id === produitGarantieId && !pg.libelle.includes('non')
        })
      }

      if (found) {
        const tmpOption = _.find(options.optionsCompatibles, (v, key) => {
          return Object.keys(v).length > 0 ? Object.keys(v)[0] == found.libelle : false
        })
        return !!tmpOption
      }
      return false
    },
    getGtNvByNiveauVariant(niveau) {
      switch (niveau) {
        case 'Economique':
          return 'light-success'
        case 'Intermédiaire':
          return 'light-warning'
        case 'Haut de gamme':
          return 'light-danger'
        default:
          return 'light-success'
      }
    },
    getGtNvByNiveau(produitGarantie, niveau) {
      const tmpGNPOption = _.find(produitGarantie.garantiesniveaux, value => value.pivot.libelle == niveau)
      return tmpGNPOption ? tmpGNPOption.garantie_niveau_libel : ''
    },
    titleClass(word) {
      return word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : ''
    },
    updateIndexDevis(value) {
      this.indexRisque = value
      this.$store.commit('setSelectedRisque', {
        selectedRisque: value,
      })
    },
    dupliquer(risque, produit) {
      const produitD = this.risqueLocal[risque].produits[produit]
      this.risqueLocal[risque].produits.push(produitD)
    },
    selectionnerProduit(risque, produit) {
      const produitsCpt = this.risqueLocal[risque].produitsFiltred.filter(pr => pr.tarif.presente)
      if (produitsCpt.length < 3) {
        this.risqueLocal[risque].produitsFiltred[produit].tarif.presente = true
      }
      if (produitsCpt.length == 3) {
        this.messageToast('', 'Vous ne pouvez pas sélectionner plus de 3 offres', 'danger', 'EditIcon')
      }
    },
    deselectionnerProduit(risque, produit) {
      this.risqueLocal[risque].produitsFiltred[produit].tarif.presente = false
      this.risqueLocal[risque].produitsFiltred[produit].tarif.preconise = false
    },
    getPtGtNvExig(risque, produit, exigence, formule) {
      const result = this.risqueLocal[risque].produitsFiltred[produit].produit.produit_garanties[0].produitgarantiesniveaux.filter(pgn => pgn.libelle === formule)
      if (result.length > 0) {
        if (result[0].exigencesniveaux.length > 0) {
          const resultFiltre = result[0].exigencesniveaux.filter(egn => egn.exigence_id === exigence)
          return resultFiltre.length > 0 ? resultFiltre[0].exigence_niveau_libel : ''
        }
      } else {
        return ''
      }
    },
    getPtGtNvExigCorrespondant(risque, produit, exigence, formule) {
      const result = this.risqueLocal[risque].produitsFiltred[produit].produit.produit_garanties[0].produitgarantiesniveaux.filter(pgn => pgn.libelle === formule)
      if (result.length > 0) {
        if (result[0].exigencesniveaux.length > 0) {
          const resultFiltre = result[0].exigencesniveaux.filter(egn => egn.exigence_id === exigence)
          return resultFiltre.length > 0 ? (resultFiltre[0].pivot ? resultFiltre[0].pivot.label_correspondant : '') : ''
        }
      } else {
        return ''
      }
    },

    comparerExig(ex1, ex2) {
      return ex1 == ex2
    },
    filtreDownUp(risque) {
      this.showCompareTable = false
      setTimeout(() => {
        this.risqueLocal[risque].produitsFiltred.sort((a, b) => a.tarif.tarif - b.tarif.tarif)
        this.showCompareTable = true
      }, 10)
    },
    filtreUpDown(risque) {
      this.showCompareTable = false
      setTimeout(() => {
        this.risqueLocal[risque].produitsFiltred.sort((a, b) => b.tarif.tarif - a.tarif.tarif)
        this.showCompareTable = true
      }, 10)
    },
    filtreByTaux(risque, type) {
      this.showCompareTable = false
      setTimeout(() => {
        this.risqueLocal[risque].produitsFiltred = type == 'UpDown' ? this.sortByTauxDec(this.risqueLocal[risque].produitsFiltred) : this.sortByTauxCr(this.risqueLocal[risque].produitsFiltred)
        this.showCompareTable = true
      }, 10)
    },
    backStep() {
      this.clearData()
    },
    clearData() {
      this.$store.commit('setQWStepTarificateur', { step: { indexStep: 1, active: 'besoins' } })
      this.$store.commit('setQWObjet', { objet: null })
      this.$store.commit('setQWProspect', { prospect: null })
      this.$store.commit('setQWConjoint', { conjoint: null })
      this.$store.commit('setQWEnfants', { enfants: [] })
      this.$store.commit('setQWItemsRisque', { itemsRisque: [] })
    },
    getTarif(selected, pg, tarif) {
      const found = pg.find(pgv => pgv.id === selected)
      const tmpTarif = found ? tarif.find(tf => tf.formule === found.libelle) : 'NaN'
      return tmpTarif.tarif
    },
    getScore(value) {
      return value ? (value * 100).toFixed(1) : '0'
    },
    arrayProduit() {
      let produitFiltre = []
      if (this.risqueLocal.length > 0) {
        this.risqueLocal.map(risque => {
          produitFiltre = []
          risque.produitsFiltred = []
          risque.produits.map(produit => {
            if (produit.tarif) {
              produit.tarif.map(tf => {
                const { tarif, ...newProduit } = produit
                const garantiesLocal = tf.garanties
                const produitsGarantiesLocal = tf.produitsgaranties
                const { garanties, ...tarifLocal } = tf
                produitFiltre.push({
                  produit: newProduit,
                  garanties: garantiesLocal,
                  produitsgaranties: produitsGarantiesLocal,
                  tarif: tarifLocal,
                })
              })
            }
          })
          if (produitFiltre.length > 0) {
            const data = {
              produits: produitFiltre,
              exigences: risque.exigences,
              garanties: risque.garanties,
              risque: risque.risque.label,
              objets: risque.objets,
            }
            const tmpProduits = selectRisqueAndProduct(data)

            risque.produitsFiltred = this.sortByTauxDec(tmpProduits)

            risque.produitsFiltred.forEach(e => {
              e.produit.document_assureur.sort((a, b) => {
                let fa = a.document.document_type.type.toLowerCase(),
                  fb = b.document.document_type.type.toLowerCase();

                if (fa < fb) {
                  return -1;
                }
                if (fa > fb) {
                  return 1;
                }

                return 0;
              });
            })
          }
        })

        
        this.filtreDownUp(0)

      }
    },
    showFile(id, type) {
      this.displayDocument(id, type, type)
    },
    sortByTauxDec(data) {
      return data.sort((a, b) => b.tarif.tauxGn - a.tarif.tauxGn || b.tarif.tauxCouv - a.tarif.tauxCouv || a.tarif.tarif.tarif - b.tarif.tarif.tarif)
    },
    sortByTauxCr(data) {
      function DownUpcompare(a, b) {
        if (a.tarif.taux == b.tarif.taux) {
          if (a.tarif.tauxtype < b.tarif.tauxtype) {
            return -1
          }
          if (a.tarif.tauxtype > b.tarif.tauxtype) {
            return 1
          }
        } else {
          if (a.tarif.taux < b.tarif.taux) {
            return -1
          }
          if (a.tarif.taux > b.tarif.taux) {
            return 1
          }
        }
        return 0
      }

      data.sort(DownUpcompare)
      return data
    },
    getExigence(egn, egnArray) {
      const tmpOption = _.find(egnArray, value => value.id === egn)
      return tmpOption ? tmpOption.exigence_niveau_libel : ''
    },
    formatText(str) {
      const map = {
        '-': ' ',
        '-': '_',
        a: 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
        e: 'é|è|ê|ë|É|È|Ê|Ë',
        i: 'í|ì|î|ï|Í|Ì|Î|Ï',
        o: 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
        u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
        c: 'ç|Ç',
        n: 'ñ|Ñ',
      }
      for (const pattern in map) { str = str.replace(new RegExp(map[pattern], 'g'), pattern) }

      return str.split(' ').join('').toUpperCase()
    },

    async onChangeNiveau(event, index, val, gr) {
      const produitTmp = this.risqueLocal[index].produitsFiltred[val]
      const ProduitsGarantiesTmp = this.risqueLocal[index].produitsFiltred[val].produitsgaranties

      ProduitsGarantiesTmp[gr].produitGarantieNiveauID[0] = event

      const { objets } = this.risqueLocal[index]
      const result = updateProduitTarif(ProduitsGarantiesTmp, produitTmp, this.risqueLocal[index].risque.label, objets)

      this.risqueLocal[index].produitsFiltred[val].tarif.tarif = result.prime
    },

    // ---------------------------------------------
  
    clearDocumentDisplay() {
      this.document.base64 = null
      this.document.name = null
      this.document.nameToDownload = null
      this.document.extensionDocument = null
    },
    displayDocument(id, name, NomDuDocument) {
      this.$http
        .get(`/document/generate_base64_for_document/${id}`)
        .then(res => {
          if (res.data.success) {
            this.clearDocumentDisplay()
            if (!res.data.data.isDownload && res.data.data.extensionDocument === 'IMAGE') {
              this.document.base64 = res.data.data.base64
              this.document.name = NomDuDocument || name
              this.document.nameToDownload = name
              this.document.extensionDocument = res.data.data.extensionDocument
              this.$refs['modal-display-document'].show()
            } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'PDF') {
              this.document.base64 = res.data.data.base64
              this.document.name = NomDuDocument || name
              this.document.nameToDownload = name
              this.document.extensionDocument = res.data.data.extensionDocument
              this.$refs['modal-display-document'].show()
            }
          } else {
            this.clearDocumentDisplay()
            this.messageToast(res.data.message, 'Erreur', 'error')
          }
        })
        .catch(err => {
          this.clearDocumentDisplay()
          this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          console.error(err)
        })
    },
    redirecToDemandeCode() {
      this.updateStatistiqueQWTarif(0, 1)
      const isAdministrateurCabinet = this.currentUser.courtier_user[0].isPrincipal
      const civiliteAdministrateurCabinet = this.capitalizeFirstLetter(this.currentUser.courtier_user[0].courtier.courtier_user[0].user.personne_physique.civilite.toLowerCase())
      const prenomAdministrateurCabinet = this.currentUser.courtier_user[0].courtier.courtier_user[0].user.personne_physique.prenom
      const nomAdministrateurCabinet = this.currentUser.courtier_user[0].courtier.courtier_user[0].user.personne_physique.nom
      if (isAdministrateurCabinet) {
        this.$router.push({ name: 'fiche-demande-ouverture-code', query: { tarification_rapide: this.statistiqueObjet.id } })
      } else {
        this.$swal({
          html: `<p class="text-justify" style="font-size: 15px">Pour faire une demande de code, merci de vous rapprocher de votre administrateur de cabinet : ${civiliteAdministrateurCabinet} ${prenomAdministrateurCabinet} ${nomAdministrateurCabinet}.</p>`,
          icon: 'info',
          showCancelButton: false,
          showConfirmButton: false
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/pages/page-pricing.scss';

.offere_thClass th {
  background-color: #ebe9f1 !important;
  color: #4d25bc;
}
</style>

<style lang="scss" scoped>
#risque_prevoyance.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}

#risque_prevoyance>.sante_risque {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
}

#risque_prevoyance>.sante_risque.active {
  background-color: #4d25bc;
  color: #fff;
}

#risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}

#addprojectform .nav-tabs .nav-link {
  background-color: rgba(77, 37, 188, 0.08);
  color: #4d25bc;
}

#addprojectform .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #4d25bc;
}

.vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle .wizard-icon-container {
  background-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  border-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li a .checked {
  background-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li a .checked i {
  color: #fff !important;
}

.wizard-btn {
  background-color: #4d25bc !important;
  border-color: #4d25bc !important;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
  border-color: #4d25bc !important;
  color: #4d25bc !important;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn:hover {
  color: #fff !important;
  background-color: #4d25bc !important;
  transition: 0.3s;
  box-shadow: 0 8px 25px -8px #4d25bc;
}

.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked~.stepTitle {
  color: #4d25bc !important;
}

@media only screen and (max-width: 768px) {
  #risque_prevoyance>.sante_risque {
    max-width: calc(50% - 2rem);
  }
}

#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}

.padding_pricing {
  padding: 0rem 1.5rem 1.5rem;
}

.propositions .card-body {
  padding: 0 !important;
}

.propositions .card-header {
  background-color: #4d25bc;
  color: #fff;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
}

.propositions .card button {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}

.box-custom-text {
  color: #7353ca;
}

.small-text {
  font-size: 12px;
  text-align: left;

}</style>
