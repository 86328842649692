<template>
    <div>
      <div v-if="activeStep.active == 'choix'" class="complement_info">
        <div class="complement_info_text py-2">
          <h1 class="p_info_1 mb-2"><b>{{ currentUser.personne_physique.prenom }} {{ currentUser.personne_physique.nom }}, BIENVENUE SUR LE TARIFICATEUR RAPIDE DE VOTRE MARKETPLACE !</b></h1>
          <p class="p_info_2"><b>Dès maintenant, vous pouvez comparer et tarifer en quelques clics</b></p>
        </div>
      </div>
      <div id="addprojectform" class="vue-form-wizard mb-3 md">
        <div class="wizard-header">
          <h4 class="wizard-title"></h4>
          <p class="category"></p>
        </div>
        <div class="wizard-navigation">
          <div class="wizard-progress-with-circle">
            <div class="wizard-progress-bar" style="background-color: rgb(115, 103, 240); color: rgb(115, 103, 240); width: 10%"></div>
          </div>
          <ul class="wizard-nav wizard-nav-pills" role="tablist">
            <li class="active" step-size="md">
              <a :class="!activeStep.active == 'choix' ? 'disabled' : 'enabled'" href="javascript:void(0)">
                <div id="step-RISQUES0" aria-controls="RISQUES0" aria-disabled="true" class="wizard-icon-circle md square_shape" role="tab" tabindex="0">
                  <div :class="indexStep == 'choix' ? 'classActive' : 'classCheck'" class="wizard-icon-container square_shape">
                    <i class="wizard-icon">1</i>
                  </div>
                </div>
                <span :class="indexStep == 'choix' ? 'classActiveText' : ''" class="stepTitle active" @click="switchStep(0, 'choix')">RISQUES</span>
              </a>
            </li>
            <li class="" step-size="md">
            <a :class="!activeStep.active == 'besoins' ? 'disabled' : 'enabled'" href="javascript:void(0)">
              <div id="step-besoins1" aria-controls="besoins1" aria-disabled="true" class="wizard-icon-circle md square_shape" role="tab" tabindex="1">
                <div :class="indexStep == 'besoins' ? 'classActive' : 'classCheck'" class="wizard-icon-container square_shape">
                  <i class="wizard-icon">2</i>
                </div>
              </div>
              <span :class="indexStep == 'besoins' ? 'classActiveText' : ''" class="stepTitle active" @click="switchStep(0, 'besoins')">BESOINS</span>
            </a>
            </li>
            <li class="" step-size="md">
            <a :class="!activeStep.active == 'comparatif' ? 'disabled' : 'enabled'" href="javascript:void(0)">
              <div id="step-COMPARATIF2" aria-controls="COMPARATIF2" aria-disabled="true" class="wizard-icon-circle md square_shape" role="tab" tabindex="">
                <div :class="indexStep == 'comparatif' ? 'classActive' : 'classCheck'" class="wizard-icon-container square_shape">
                  <i class="wizard-icon">3</i>
                </div>
              </div>
              <span :class="indexStep == 'comparatif' ? 'classActiveText' : ''" class="stepTitle active" @click="switchStep(0, 'comparatif')">TARIFS</span>
            </a>
          </li>
          </ul>
          <div style="padding: 1.42rem 1.42rem 2.14rem 1.42rem">
            <div v-show="indexStep == 'choix'">
              <Choix-risque ref="choix"></Choix-risque>
              
            </div>
            <div v-show="indexStep == 'besoins'">
              <Besoins-parent ref="besoins"></Besoins-parent>
            </div>
            <div v-show="indexStep == 'comparatif'">
              <Tarifs-parent ref="comparatif"></Tarifs-parent>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid" v-if="activeStep.active == 'choix'">

        <b-row class="products">
  
            <b-col  lg="3" md="12"  class="py-2" v-for="(item,i) of produits" :key="i"> 
              <div class="product p-1">
                  <h4 class="text-uppercase text-primary text-center" v-html="item.svg"></h4>
                  <div class="d-flex justify-content-center align-items-center" >
                    <div class="box_img" style="width: 100px; height: 100px;">
                      <!-- <img v-if="item.logo == 'spvie'" style="max-width: 100%; height: 75px;" :src="require('@/assets/images/logo/assureur/spvie.png')"  alt="spvie"> -->
                      <img style="max-width: 100%; height: 75px;" :src="item.assureur_image" alt="">
                    </div>
                    <div style="margin-top: 1rem;" class="box_text text-center font-weight-bold ml-1">
                      <p class="mb-0" style="color: black;" v-html ="item.first_paragraph"> </p>
                      <p class="mb-0 text-primary">{{item.details}}</p>

                    </div>
                  </div>
                </div>
            </b-col>
              <!-- <b-col  lg="3" md="12"   class="py-2">
                <div class="product p-1">
                  <h4 class="text-uppercase text-primary text-center"><span>
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                      <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 3C4.239 3 2 5.216 2 7.95c0 2.207.875 7.445 9.488 12.74a.985.985 0 0 0 1.024 0C21.125 15.395 22 10.157 22 7.95C22 5.216 19.761 3 17 3s-5 3-5 3s-2.239-3-5-3Z" />
                    </svg>
                    SANTE TNS
                  </span></h4>
                  <div class="d-flex justify-content-center align-items-center">
                    <div class="box_img" style="width: 110px; height: 100px;">
                      <img style="max-width: 100%;" height="60" src="../../../../public/logo_harmonie_partener.png" alt="">
                    </div>
                    <div style="    margin-top: 1rem;" class="box_text text-center font-weight-bold ml-1">
                      <p class="mb-0" style="color: black;">Cible et âge de souscription :</p>
                      <p class="mb-0 text-primary">TNS de 18 à 65 ans</p>
  
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col  lg="3" md="12"   class="py-2">
                <div class="product p-1">
                  <h4 class="text-uppercase text-primary text-center"><span>
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 2304 1280">
                      <path fill="currentColor" d="M2301 780q12 103-22 198.5t-99 163.5t-158.5 106t-196.5 31q-161-11-279.5-125T1411 880q-12-111 27.5-210.5T1557 499l-71-107q-96 80-151 194t-55 244q0 27-18.5 46.5T1216 896H891q-23 164-149 274t-294 110q-185 0-316.5-131.5T0 832t131.5-316.5T448 384q76 0 152 27l24-45Q501 256 320 256h-64q-26 0-45-19t-19-45t19-45t45-19h128q78 0 145 13.5T645.5 180t71.5 39.5t51 36.5h627l-85-128h-222q-30 0-49-22.5T1025 53q4-23 23-38t43-15h253q33 0 53 28l70 105l114-114q19-19 46-19h101q26 0 45 19t19 45v128q0 26-19 45t-45 19h-179l115 172q131-63 275-36q143 26 244 134.5T2301 780zM448 1152q115 0 203-72.5T762 896H448q-35 0-55-31q-18-32-1-63l147-277q-47-13-91-13q-132 0-226 94t-94 226t94 226t226 94zm1408 0q132 0 226-94t94-226t-94-226t-226-94q-60 0-121 24l174 260q15 23 10 49t-27 40q-15 11-36 11q-35 0-53-29l-174-260q-93 95-93 225q0 132 94 226t226 94z" />
                    </svg>
                    2 ROUES
                  </span></h4>
                  <div class="d-flex justify-content-center align-items-center">
                    <div class="box_img" style="width: 100px; height: 100px;">
                      <img style="max-width: 100%;" src="../../../../public/FMA.png" alt="">
                    </div>
                    <div style="    margin-top: 1rem;" class="box_text text-center font-weight-bold ml-1">
                      <p class="mb-0" style="color: black;">Cible et âge de souscription :</p>
                      <p class="mb-0 text-primary">Motards à partir de 16 ans</p>
  
                    </div>
                  </div>
                </div>
              </b-col> -->
      </b-row>
      </div>
    </div>
  </template>
  
  <script>
  import { FormWizard, TabContent } from 'vue-form-wizard'
  import vSelect from 'vue-select'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import flatPickr from 'vue-flatpickr-component'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import ChoixRisque from './ChoixRisque.vue'
  import BesoinsParent from './besoins/BesoinsParent.vue'
  import TarifsParent from './tarifs/TarifsParent.vue'
  import { mapGetters } from 'vuex'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import { BButton, BCard, BCardText, BCol, BFormCheckboxGroup, BFormGroup, BFormInput, BFormInvalidFeedback, BFormRadio, BFormRadioGroup, BFormSelect, BFormTextarea, BListGroup, BListGroupItem, BRow, BTab, BTable, BTableSimple, BTabs, BTbody, BTd, BTh, BThead, BTr } from 'bootstrap-vue'
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      FormWizard,
      TabContent,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      vSelect,
      BFormInvalidFeedback,
      // eslint-disable-next-line vue/no-unused-components
      ToastificationContent,
      BFormRadioGroup,
      BFormRadio,
      BFormCheckboxGroup,
      BTabs,
      BTab,
      flatPickr,
      BFormSelect,
      BTable,
      BTableSimple,
      BThead,
      BTr,
      BTh,
      BTd,
      BTbody,
      BButton,
      BCard,
      BCardText,
      BListGroup,
      BListGroupItem,
      BFormTextarea,
      ChoixRisque,
      BesoinsParent,
      TarifsParent
    },
    computed: {
      ...mapGetters(['getQWActiveStep']),
      ...mapGetters(['getQWStep'])
    },
    data() {
      return {
        indexStep: 'choix',
        activeStep: { indexStep: 0, active: 'choix'},
        tarifStep: 0,
        currentUser: JSON.parse(localStorage.getItem('userData')),
        produits :[],
        our_products:[
          {
            id: 1,
            title: "sante senior",
            svg: '<span><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke- linecap="round" stroke - linejoin="round" stroke - width="2" d = "M7 3C4.239 3 2 5.216 2 7.95c0 2.207.875 7.445 9.488 12.74a.985.985 0 0 0 1.024 0C21.125 15.395 22 10.157 22 7.95C22 5.216 19.761 3 17 3s-5 3-5 3s-2.239-3-5-3Z" /></svg></span><span class="title-partenaire">Sante senior</span>',
            logo: "logo_swiss_life_partener.png",
            description: "Cible et âge de souscription",
            details: "Senior de 55 à 85 ans",
          },
          {
            id:2,
            title: "sante tns",
            svg: '<span><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke- linecap="round" stroke - linejoin="round" stroke - width="2" d = "M7 3C4.239 3 2 5.216 2 7.95c0 2.207.875 7.445 9.488 12.74a.985.985 0 0 0 1.024 0C21.125 15.395 22 10.157 22 7.95C22 5.216 19.761 3 17 3s-5 3-5 3s-2.239-3-5-3Z" /></svg></span><span class="title-partenaire">Sante tns</span>',
            logo: "logo_harmonie_partener.png",
            description: "Cible et âge de souscription",
            details: "TNS de 18 à 65 ans",
          },
          {
            id:3,
            title: "2 roues",
            svg: '<span><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 2304 1280"><path fill="currentColor" d="M2301 780q12 103-22 198.5t-99 163.5t-158.5 106t-196.5 31q-161-11-279.5-125T1411 880q-12-111 27.5-210.5T1557 499l-71-107q-96 80-151 194t-55 244q0 27-18.5 46.5T1216 896H891q-23 164-149 274t-294 110q-185 0-316.5-131.5T0 832t131.5-316.5T448 384q76 0 152 27l24-45Q501 256 320 256h-64q-26 0-45-19t-19-45t19-45t45-19h128q78 0 145 13.5T645.5 180t71.5 39.5t51 36.5h627l-85-128h-222q-30 0-49-22.5T1025 53q4-23 23-38t43-15h253q33 0 53 28l70 105l114-114q19-19 46-19h101q26 0 45 19t19 45v128q0 26-19 45t-45 19h-179l115 172q131-63 275-36q143 26 244 134.5T2301 780zM448 1152q115 0 203-72.5T762 896H448q-35 0-55-31q-18-32-1-63l147-277q-47-13-91-13q-132 0-226 94t-94 226t94 226t226 94zm1408 0q132 0 226-94t94-226t-94-226t-226-94q-60 0-121 24l174 260q15 23 10 49t-27 40q-15 11-36 11q-35 0-53-29l-174-260q-93 95-93 225q0 132 94 226t226 94z" /></svg></span><span class="title-partenaire">2-roues</span>',
            logo: "FMA.png",
            description: "Cible et âge de souscription",
            details: "Motards à partir de 16 ans",
          },
          {
            id:4,
            title: "capital senior",
            svg: '<span><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke- linecap="round" stroke - linejoin="round" stroke - width="2" d = "M7 3C4.239 3 2 5.216 2 7.95c0 2.207.875 7.445 9.488 12.74a.985.985 0 0 0 1.024 0C21.125 15.395 22 10.157 22 7.95C22 5.216 19.761 3 17 3s-5 3-5 3s-2.239-3-5-3Z" /></svg></span><span class="title-partenaire">Capital senior</span>',
            logo: "Eca-assurances.png",
            description: "Cible et âge de souscription",
            details: "Senior de 55 à 95 ans",
          },
           {
            id: 5,
            title: "sante senior",
            svg: '<span><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke- linecap="round" stroke - linejoin="round" stroke - width="2" d = "M7 3C4.239 3 2 5.216 2 7.95c0 2.207.875 7.445 9.488 12.74a.985.985 0 0 0 1.024 0C21.125 15.395 22 10.157 22 7.95C22 5.216 19.761 3 17 3s-5 3-5 3s-2.239-3-5-3Z" /></svg></span><span class="title-partenaire">Capital senior</span>',
            logo: "spvie",
            description: "Cible et âge de souscription",
            details: "Senior de 55 à 85 ans",
          },
        ],
      }
    },

    watch: {
      getQWStep: {
        handler(val) {
          this.indexStep = val
        },
        deep: true,
        immediate: true
      },
      getQWActiveStep: {
        handler(val) {
          this.activeStep = val
        },
        deep: true,
        immediate: true
      },
    },
    mounted(){
      this.getLogoName()
    },
    beforeDestroy() {
      this.$store.dispatch('resetQWState')
    },
    methods :{
      getLogoName() {
            this.showLoading = true
            this.$http.get(`/getLogoProduits`)
            .then((res) => {
              this.produits = res.data
              console.log(this.produits)
              this.showLoading = false
              this.produits.forEach((produit) => {
                if (produit.id == '19') {
                  produit.first_paragraph = '<strong>CAPITAL SENIOR	</strong><br>Cible et âge de souscription :'
                }
                else if (produit.id == '23') {
                  produit.first_paragraph = '<strong>SERENISSIA </strong><br>Cible et âge de souscription :'
                }
                else if (produit.id == '24') {
                  produit.first_paragraph = '<strong>ACTISAN </strong><br>Cible et âge de souscription :'
                }
                else if (produit.id == '27' ) {
                  produit.first_paragraph = '<strong>SPVIE Santé Malin </strong><br>Cible et âge de souscription :'
                }
                else if (produit.id == '26' ) {
                  produit.first_paragraph = '<strong>SPVIE Santé Smart </strong><br>Cible et âge de souscription :'
                }
                else {
                  produit.first_paragraph = 'Cible et âge de souscription :'
                }
              })
              this.sortProducts();
            })
            .catch((e) => {
                console.log(e);
                this.showLoading = false
            })
      },
        
      sortProducts() {
        const order = ['ADP', 'ADP2', 'IARD'];
        const adpProducts = this.produits.filter(product => product.type === 'ADP');
        adpProducts.sort((a, b) => a.nom_com_assureur.localeCompare(b.nom_com_assureur));
        adpProducts.sort((a, b) => b.prefixe.localeCompare(a.prefixe));

        const nonAdpProducts = this.produits.filter(product => product.type !== 'ADP');
        nonAdpProducts.sort((a, b) => order.indexOf(a.nom_com_assureur) - order.indexOf(b.nom_com_assureur));
        nonAdpProducts.sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type));

        

        this.produits = [...adpProducts, ...nonAdpProducts];
    },
      
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/pages/page-pricing.scss';
  
  .box-wizard-custom {
    padding: 2rem 4.5rem 4.5rem !important;
  }
  </style>
  
  <style lang="scss" scoped>
  #risque_prevoyance.btn-group {
    display: inline-flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-between;
  }
  
  #risque_prevoyance > .sante_risque {
    min-width: calc(25% - 2rem);
    margin: 1rem;
    padding: 25px 15px;
    border-right: 1px solid #4d25bc !important;
    border-radius: 0.358rem;
  }
  
  #risque_prevoyance > .sante_risque.active {
    background-color: #4d25bc;
    color: #fff;
  }
  
  #risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
    background-color: #4d25bc;
    color: #fff;
    transition: 0.4s;
  }
  
  #addprojectform .nav-tabs .nav-link {
    background-color: rgba(77, 37, 188, 0.08);
    color: #4d25bc;
  }
  
  #addprojectform .nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #4d25bc;
  }
  
  @media only screen and (max-width: 768px) {
    #risque_prevoyance > .sante_risque {
      max-width: calc(50% - 2rem);
    }
  }
  
  #addprojectform h4 span {
    font-size: 15px;
    color: #000;
  }
  
  .offere_thClass th {
    background-color: #4d25bc !important;
    color: #fff;
  }
  
  .padding_pricing {
    padding: 0rem 1.5rem 1.5rem;
  }
  
  .propositions .card-body {
    padding: 0 !important;
  }
  
  .propositions .card-header {
    background-color: #4d25bc;
    color: #fff;
    justify-content: center;
    font-weight: bold;
    font-size: 1.2rem;
  }
  
  .propositions .card button {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    padding: 15px 0px;
  }
  
  .classCheck {
    border-color: rgb(115, 103, 240);
    background-color: rgba(115, 103, 240, 0.08);
  }
  
  .classCheck > i {
    color: #4d25bc !important;
  }
  
  .classActive {
    border-color: #4d25bc;
    background-color: #4d25bc;
  }
  
  .classActive > i {
    color: #fff !important;
  }
  
  .classActiveText {
    color: #4d25bc !important;
  }
  .complement_info_text .p_info_1{
    color: #4d25bc !important;
    text-transform: uppercase;
  }
  .complement_info_text .p_info_2{
    font-size: 1.8rem;
    color: black;
  }
  .products{
    background-color: #fff;
    box-shadow: 0px 4px 25px 0px rgba(34, 41, 47, 0.1);
    border-radius: 0.5rem;
    padding-bottom: 0;
    margin-bottom: 5rem;
  }
  .product{
    border-radius: 5px;
    min-height: 160px;
    box-shadow: 0px 4px 25px 0px rgba(34, 41, 47, 0.1);

  }
  </style>
  